import type { ICountries, ICountryCode } from '.';

import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import Select, { components } from 'react-select';
import { Image } from '@storybook/image';

import countries from './json/country-codes.json';

import './country-code.scss';
import { isMobileDevice } from 'utils';

import Popup from 'rmc-picker/es/Popup';
import 'rmc-picker/assets/index.css';
import 'rmc-picker/assets/popup.css';
import { Input } from '@storybook/input';
import { v4 } from 'uuid';

export const CountryCode: FC<ICountryCode> = ({
	handleChangeCountry,
	countryCode = '+1',
	optionsClassName = '',
	className = '',
	isDisabled = false,
}) => {
	const [country, setCountry] = useState<ICountries>();
	const [popupVisible, setPopupVisible] = useState(false);
	const [tempValue, setTempValue] = useState<ICountries>();
	const [searchTerm, setSearchTerm] = useState('');
	const [filteredOptions, setFilteredOptions] =
		useState<ICountries[]>(countries);

	const customStyles = {
		control: (styles: any) => ({
			...styles,
			cursor: 'pointer',
			border: 'none',
			boxShadow: 'none',
			backgroundColor: 'transparent',
			'&:hover': {
				border: 'none',
				borderRadius: '5px',
				boxShadow: 'none',
			},
		}),
		menu: (styles: any) => ({
			...styles,
			width: '350px', // Make the dropdown menu 100% wide
			'@media only screen and (max-width: 430px)': {
				width: '330px',
			},
		}),
	};

	useEffect(() => {
		const countryObj = countries.find(item => item.label === countryCode);
		if (countryObj && countryObj.label !== country?.label) {
			setCountry(countryObj);
			setTempValue(countryObj);
		}
	}, [countryCode, country]);

	const handleCountryChange = (selectedOption: ICountries | null) => {
		if (selectedOption) {
			setCountry(selectedOption);
			handleChangeCountry(selectedOption);
		}
	};

	// Custom SingleValue component to show only the flag and country code after selection
	const SingleValue = (props: any) => {
		return (
			<components.SingleValue {...props}>
				<div className={`option ${optionsClassName}`}>
					<div className="skeleton-image-loader-wrapper">
						<Image className="option__flag" url={props.data.image} />
						<div className="skeleton-image-loader"></div>
					</div>
					<span className="option__label">{props.data.label}</span>
				</div>
			</components.SingleValue>
		);
	};

	const handleDismiss = () => {
		setPopupVisible(false);
		setSearchTerm('');
	};

	const handleSearch = useCallback((value: string) => {
		const optionsList = countries.filter(
			option =>
				option.label.toLowerCase().includes(value.toLowerCase()) ||
				option.value.toLowerCase().includes(value.toLowerCase())
		);
		setFilteredOptions(optionsList);
		setSearchTerm(value);
	}, []);

	const handleMobileChange = (newValue: ICountries) => {
		setTempValue(newValue);
		if (handleCountryChange) handleCountryChange(newValue);
		handleDismiss();
	};

	const dropdownOptions = useMemo(
		() => (searchTerm ? filteredOptions : countries),
		[filteredOptions, searchTerm]
	);

	if (isMobileDevice()) {
		return (
			<Popup
				visible={popupVisible}
				onDismiss={handleDismiss}
				// onOk={handleOk}
				okText=""
				content={
					<Fragment>
						<Input
							inputType="text"
							placeholder={'Search..'}
							handleChange={e => handleSearch(e.target.value)}
							label=""
							className="input-mobile-search"
							value={searchTerm}
						/>
						<div className="input-mobile--body">
							{dropdownOptions.map(option => (
								<div
									key={v4()}
									onClick={() => handleMobileChange(option)}
									className={` option ${optionsClassName} input-mobile--list`}
								>
									<div className="skeleton-image-loader-wrapper">
										<Image className="option__flag" url={option.image} />
										<div className="skeleton-image-loader"></div>
									</div>
									<span className="option__label">
										{option.value}
										<span className={'option__label-code'}>{option.label}</span>
									</span>
								</div>
							))}
						</div>
					</Fragment>
				}
			>
				<div
					onClick={() => {
						setPopupVisible(true);
					}}
					className={isDisabled ? 'CountryCode__disabled' : ''}
				>
					<div className={'CountryCode__input-box'}>
						<div className="CountryCode__flag-container">
							<div className="CountryCode__flag-container-flag">
								<Image
									className="option__flag"
									url={country?.image ?? tempValue?.image}
								/>
							</div>
							<span className="option__label">
								{country?.label ?? tempValue?.label}
							</span>
						</div>
						<i className="ri-arrow-drop-down-line"></i>
					</div>
					{/* <Input
						readOnly
						inputType="text"
						placeholder={'Select...'}
						className={`country-label ${className}`}
						handleChange={() => {}}
						value={country?.label ?? tempValue?.label ?? ''}
						label={''}
					/> */}
				</div>
			</Popup>
		);
	}

	return country ? (
		<Select
			defaultValue={country}
			styles={customStyles}
			formatOptionLabel={(countryData: ICountries) => {
				const isSelected = countryData.label === country.label;
				return (
					<div className={`option ${optionsClassName}`}>
						<div className="skeleton-image-loader-wrapper">
							<Image className="option__flag" url={countryData.image} />
							<div className="skeleton-image-loader"></div>
						</div>
						<span className="option__label">
							{countryData.value}
							<span className={!isSelected ? 'option__label-code' : ''}>
								{countryData.label}
							</span>
						</span>
					</div>
				);
			}}
			className={`country-label ${className}`}
			options={countries}
			getOptionValue={(option: ICountries) => option.value}
			onChange={handleCountryChange}
			isSearchable
			isDisabled={isDisabled}
			value={country}
			components={{
				SingleValue,
				IndicatorSeparator: () => null,
			}}
			maxMenuHeight={140}
		/>
	) : (
		<></>
	);
};
