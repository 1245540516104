import {
	FC,
	createElement,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import * as SurveyCore from 'survey-core';
import {
	Model,
	StylesManager,
	ComponentCollection,
	SvgRegistry,
	settings,
} from 'survey-core';
import { ReactQuestionFactory, Survey } from 'survey-react-ui';
import * as widgets from 'surveyjs-widgets';
import { inputmask } from 'surveyjs-widgets';
import classNames from 'classnames';

import {
	useNextStep,
	useNotification,
	useSharedVariables,
	useUrl,
} from 'hooks';
import { useTokenSession } from 'hooks/token/token-session';
import { AccessTokenState, isShowSkipState } from 'states';

import { BodyWrapper } from 'components/body-wrapper';
import { IQuestionPlainData } from 'survey-core/typings/question';

import 'survey-core/defaultV2.min.css';
import { SurveyQuestionColorPicker } from 'components/investor-questionnair/investor-questionnair';
import { headers } from 'views/constants';
import { useInvestorDetails } from 'components/investor-questionnair/investor-details-form';
import { API_TYPE } from '../../../../../constants';
import { EntityVerificationTitle, useSurveyData } from '../../../stores';
import {
	QuantitySelectorQuestion,
	CustomAddressPicker,
	CustomCountryQuestion,
	CustomCountryStateQuestion,
} from 'components';

import { Loader } from '@storybook';
import { throttle } from 'utils';
import { EVENTS_TRACKING, timestamp, useTrackEvents } from 'helpers';
StylesManager.applyTheme('defaultV2');

inputmask(SurveyCore);
widgets.inputmask(SurveyCore);

interface IQuestionnire {
	setLoading: (el: boolean) => void;
	isLoading: boolean;
}

ComponentCollection.Instance.add({
	name: 'mcc',
	title: 'MCC',
	questionJSON: {
		type: 'dropdown',
		title: 'Select mcc...',
		choicesByUrl: {
			url: 'https://rhiegx7hc6esd2ssihgil2nf2q0ncgvj.lambda-url.us-east-1.on.aws/',
		},
	},
});

const CUSTOM_TYPE = 'investors-detail';
const { Question, Serializer, ElementFactory } = SurveyCore;

// QuestionAddressPicker
class QuestionAddressPicker extends Question {
	getType() {
		return 'addressPicker';
	}
}

Serializer.addClass(
	'addressPicker',
	[
		{
			name: 'addressPicker:boolean',
			dependsOn: 'addressPicker',
			visibleIf: () => false,
			category: 'general',
		},
	],
	function () {
		return new QuestionAddressPicker('');
	},
	'question'
);
ElementFactory.Instance.registerElement('addressPicker', name => {
	return new QuestionAddressPicker(name);
});

ReactQuestionFactory.Instance.registerQuestion('addressPicker', props => {
	return createElement(CustomAddressPicker, props);
});

SvgRegistry.registerIconFromSvg(
	'addressPicker',
	'<svg class="sv-svg-icon sv-list__item-icon" role="img" style="width: 24px; height: 24px;"><use xlink:href="#icon-matrix"></use></svg>'
);

//QuestionCountryStateModel
class QuestionCountryStateModel extends Question {
	getType() {
		return 'countryStateSelector';
	}
}

Serializer.addClass(
	'countryStateSelector',
	[
		{
			name: 'countryStateSelector:boolean',
			dependsOn: 'countryStateSelector',
			visibleIf: () => false,
			category: 'general',
		},
	],
	function () {
		return new QuestionCountryStateModel('');
	},
	'question'
);
ElementFactory.Instance.registerElement('countryStateSelector', name => {
	return new QuestionCountryStateModel(name);
});

ReactQuestionFactory.Instance.registerQuestion(
	'countryStateSelector',
	props => {
		return createElement(CustomCountryStateQuestion, props);
	}
);

SvgRegistry.registerIconFromSvg(
	'countryStateSelector',
	'<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M17.0839 15.812C19.6827 13.0691 19.6379 8.73845 16.9497 6.05025C14.2161 3.31658 9.78392 3.31658 7.05025 6.05025C4.36205 8.73845 4.31734 13.0691 6.91612 15.812C7.97763 14.1228 9.8577 13 12 13C14.1423 13 16.0224 14.1228 17.0839 15.812ZM8.38535 17.2848L12 20.8995L15.6147 17.2848C14.9725 15.9339 13.5953 15 12 15C10.4047 15 9.0275 15.9339 8.38535 17.2848ZM12 23.7279L5.63604 17.364C2.12132 13.8492 2.12132 8.15076 5.63604 4.63604C9.15076 1.12132 14.8492 1.12132 18.364 4.63604C21.8787 8.15076 21.8787 13.8492 18.364 17.364L12 23.7279ZM12 10C12.5523 10 13 9.55228 13 9C13 8.44772 12.5523 8 12 8C11.4477 8 11 8.44772 11 9C11 9.55228 11.4477 10 12 10ZM12 12C10.3431 12 9 10.6569 9 9C9 7.34315 10.3431 6 12 6C13.6569 6 15 7.34315 15 9C15 10.6569 13.6569 12 12 12Z"></path></svg>'
);

//QuestionCountryModel
class QuestionCountryModel extends Question {
	getType() {
		return 'countrySelector';
	}
}

Serializer.addClass(
	'countrySelector',
	[
		{
			name: 'countrySelector:boolean',
			dependsOn: 'countrySelector',
			visibleIf: () => false,
			category: 'general',
		},
	],
	function () {
		return new QuestionCountryModel('');
	},
	'question'
);
ElementFactory.Instance.registerElement('countrySelector', name => {
	return new QuestionCountryModel(name);
});

ReactQuestionFactory.Instance.registerQuestion('countrySelector', props => {
	return createElement(CustomCountryQuestion, props);
});

SvgRegistry.registerIconFromSvg(
	'countrySelector',
	'<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M17.0839 15.812C19.6827 13.0691 19.6379 8.73845 16.9497 6.05025C14.2161 3.31658 9.78392 3.31658 7.05025 6.05025C4.36205 8.73845 4.31734 13.0691 6.91612 15.812C7.97763 14.1228 9.8577 13 12 13C14.1423 13 16.0224 14.1228 17.0839 15.812ZM8.38535 17.2848L12 20.8995L15.6147 17.2848C14.9725 15.9339 13.5953 15 12 15C10.4047 15 9.0275 15.9339 8.38535 17.2848ZM12 23.7279L5.63604 17.364C2.12132 13.8492 2.12132 8.15076 5.63604 4.63604C9.15076 1.12132 14.8492 1.12132 18.364 4.63604C21.8787 8.15076 21.8787 13.8492 18.364 17.364L12 23.7279ZM12 10C12.5523 10 13 9.55228 13 9C13 8.44772 12.5523 8 12 8C11.4477 8 11 8.44772 11 9C11 9.55228 11.4477 10 12 10ZM12 12C10.3431 12 9 10.6569 9 9C9 7.34315 10.3431 6 12 6C13.6569 6 15 7.34315 15 9C15 10.6569 13.6569 12 12 12Z"></path></svg>'
);

//QuantitySelectorQuestion
class QuantitySelectorQuestionModel extends Question {
	getType() {
		return 'fundInvestmentAmount';
	}
}
Serializer.addClass(
	'fundInvestmentAmount',
	[
		{
			name: 'fundInvestmentAmount:boolean',
			dependsOn: 'fundInvestmentAmount',
			visibleIf: () => false,
			category: 'general',
		},
	],
	function () {
		return new QuantitySelectorQuestionModel('');
	},
	'question'
);
ElementFactory.Instance.registerElement('fundInvestmentAmount', name => {
	return new QuantitySelectorQuestionModel(name);
});

ReactQuestionFactory.Instance.registerQuestion(
	'fundInvestmentAmount',
	props => {
		return createElement(QuantitySelectorQuestion, props);
	}
);

SvgRegistry.registerIconFromSvg('fundInvestmentAmount', '');

// investors-detail
export class QuestionColorPickerModel extends Question {
	getType() {
		return CUSTOM_TYPE;
	}

	get disableAlpha() {
		return this.getPropertyValue('investors-detail');
	}
	set disableAlpha(val) {
		this.setPropertyValue('investors-detail', val);
	}
}

Serializer.addClass(
	CUSTOM_TYPE,
	[],
	function () {
		return new QuestionColorPickerModel('');
	},
	'question'
);

ElementFactory.Instance.registerElement(CUSTOM_TYPE, name => {
	return new QuestionColorPickerModel(name);
});

ReactQuestionFactory.Instance.registerQuestion(CUSTOM_TYPE, props => {
	return createElement(SurveyQuestionColorPicker, props);
});

settings.lazyRender.enabled = true;

export const Questionnire: FC<IQuestionnire> = ({ setLoading }) => {
	// globle state
	const setIsShowSkip = useSetRecoilState(isShowSkipState);
	const { code: sessionCode } = useRecoilValue(AccessTokenState);
	const { ValidateSubmit, shareholderThresholdValues } = useInvestorDetails();
	const { trackEvents } = useTrackEvents();
	// local state
	const { postTokenSession } = useTokenSession();
	const { fetchSurveyData } = useSurveyData();
	const { postTokenSession: postAutoSaveAuestionnaire } = useTokenSession();
	const {
		handleNext,
		sessionDetails,
		sessionPayloadDetail,
		setSessionDetails,
	} = useNextStep();
	const { errorNotification, warningNotification } = useNotification();
	const { onboardingType, qrAllowInvite } = useSharedVariables();
	const { code } = useUrl();

	const [isPreFillLoad, setPreFillLoad] = useState(true);
	const { _id, userId, currentAction, stepsId } = useMemo(
		() => sessionPayloadDetail ?? {},
		[sessionPayloadDetail]
	);

	const foundForm = useMemo(() => {
		if (onboardingType === 'complex') {
			const form =
				currentAction.metadata?.formAction ?? currentAction.metadata?.questions;
			if (form) {
				return form;
			}
			return sessionDetails?.nodes?.actions?.[0]?.metadata?.questions;
		}
		return (
			sessionDetails.steps
				?.find(step => step.stepId === 'form')
				?.actions?.find(action => action.key === 'formAction')?.metadata
				?.formAction ?? {}
		);
	}, [
		onboardingType,
		sessionDetails.steps,
		sessionDetails?.nodes?.actions,
		currentAction.metadata?.formAction,
		currentAction.metadata?.questions,
	]);

	const questionnairData = useMemo(() => {
		if (foundForm) {
			const foundData = structuredClone(foundForm);
			foundData.title = '';
			foundData.description = '';
			return foundData;
		} else return foundForm;
	}, [foundForm]);

	const survey = useMemo(() => new Model(questionnairData), [questionnairData]);

	useEffect(() => {
		if (qrAllowInvite) {
			survey.applyTheme({
				isPanelless: true,
			});
		}
		// Check if a form is found before attempting to fetch survey data
		if (foundForm) {
			// Fetch survey data based on the found form and assign it to survey.data
			survey.data = {
				...(survey?.data ?? {}),
				...(fetchSurveyData(foundForm) ?? {}),
			};
		}

		if (sessionDetails?.organization?.colorScheme?.brandColor) {
			document.documentElement.style.setProperty(
				'--sjs-primary-backcolor',
				sessionDetails.organization.colorScheme.brandColor
			);
		}

		// Optional: If you want to handle cleanup or dependencies in the future,
		// you can add those here. For now, the eslint disable comment can be omitted.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [foundForm]);

	const surveyData = useCallback(() => {
		const data = survey.getPlainData();
		if (Array.isArray(data) && data.length) {
			data.forEach((dataItem: any) => {
				dataItem.type = survey
					?.getSurveyData()
					?.findQuestionByName(dataItem.name)
					?.getType();
			});
		}
		return data;
	}, [survey]);

	const investerDetailFormPostRequest = useCallback(async () => {
		const getSurveyData = surveyData();
		const investorsDetails = getSurveyData?.[0]?.value?.investorsDetails;
		const formType =
			sessionDetails?.nodes?.actions[0]?.metadata?.questions?.pages[0]
				?.elements;
		const isInvestorsDetailTypeExist = formType.some(
			(form: any) => form.type === 'investors-detail'
		);
		if (isInvestorsDetailTypeExist) {
			const threasholdKycValues = shareholderThresholdValues();
			// user who create the qr
			const senderEmail = sessionDetails?.email;
			const getRowsData = investorsDetails
				?.filter(
					({ shareOwned, email, disableRow }: any) =>
						senderEmail !== email.value &&
						Number(shareOwned.value || 0) >=
							threasholdKycValues.complianceSessionThreshold &&
						disableRow === false
				)
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				.map(({ shareOwned, ...rest }: any) => {
					const { countryCode, email, firstName, lastName, phoneNumber } = rest;
					return {
						firstName: firstName.value,
						lastName: lastName.value,
						email: email.value,
						phoneNumber: phoneNumber.value,
						countryCode: countryCode.value,
						DollarsInvested: 0,
					};
				})
				.map((user: any) => Object.values(user));
			const payload = {
				users: {
					headers: headers,
					rows: getRowsData,
				},
				alerts: {
					email: true,
					text: true,
				},
			};

			await postTokenSession({
				code,
				type: API_TYPE.INVESTOR_INVITE,
				payload,
			});
		}
	}, [
		code,
		sessionDetails,
		surveyData,
		shareholderThresholdValues,
		postTokenSession,
	]);

	const submitRequest = useCallback(
		async (sender: { data: any }) => {
			setLoading(true);

			const results = sender.data;
			const completeSurveyFormData = surveyData();
			const threasholdKycValues = shareholderThresholdValues();
			completeSurveyFormData.forEach((surveyDataItem: IQuestionPlainData) => {
				if (surveyDataItem.type === CUSTOM_TYPE) {
					surveyDataItem.complianceSessionThreshold =
						threasholdKycValues.complianceSessionThreshold;
				}
			});
			if (
				completeSurveyFormData[0]?.displayValue &&
				completeSurveyFormData[0]?.title === EntityVerificationTitle
			) {
				trackEvents(EVENTS_TRACKING.VERIFICATION_TYPE_SELECTED, {
					type: completeSurveyFormData[0]?.displayValue ?? '',
					timestamp: timestamp,
				});
			}
			const payload: any = {
				pipelineId: _id,
				userId,
				actions: [
					{
						id: currentAction?.key,
						data: {
							survey: completeSurveyFormData,
							answers: results,
						},
					},
				],
			};
			if (onboardingType === 'complex') {
				payload.nodeId = currentAction._id;

				const finalPayload = {
					payload,
					code: sessionCode,
				};
				const res = await postTokenSession(finalPayload);
				if (res?.statusCode === 200) {
					const formResponse = { ...res };
					delete formResponse.statusCode;
					setSessionDetails(prev => ({
						...prev,
						nodes: formResponse,
						fundName: formResponse?.fundName ?? '',
					}));
				} else {
					if (
						res?.statusCode === 0 ||
						res?.statusCode === undefined ||
						res?.statusCode === null
					) {
						warningNotification(
							'No response received. This might be due to a network problem. Please retry.'
						);
					} else {
						errorNotification('Something went wrong , Please Skip');
					}
				}
				trackEvents(EVENTS_TRACKING.QUESTIONNAIRE_COMPLETED, {
					timestamp: timestamp,
				});
				setLoading(false);
				return;
			}

			payload.stepId = stepsId;
			//KYC_SESSION api

			const res = await postTokenSession({ payload, code: sessionCode });
			if (res?.statusCode === 200) {
				handleNext();
			} else {
				if (
					res?.statusCode === 0 ||
					res?.statusCode === undefined ||
					res?.statusCode === null
				) {
					warningNotification(
						'No response received. This might be due to a network problem. Please retry.'
					);
				} else {
					errorNotification('Something went wrong , Please Skip');
				}
				setIsShowSkip(true);
			}
			setLoading(false);
		},
		[
			setLoading,
			surveyData,
			shareholderThresholdValues,
			_id,
			userId,
			currentAction?.key,
			currentAction._id,
			onboardingType,
			stepsId,
			postTokenSession,
			sessionCode,
			setSessionDetails,
			warningNotification,
			errorNotification,
			handleNext,
			setIsShowSkip,
			trackEvents,
		]
	);

	const questionValidate = (_: any, data: any) => {
		const investorsDetails = data?.value?.investorsDetails;
		const threasholdKycValues = shareholderThresholdValues();
		// Log the investorsDetails array
		if (investorsDetails) {
			const anyMajorShareHolder = investorsDetails.some(
				(investor: { shareOwned: any }) =>
					investor.shareOwned.value >=
					threasholdKycValues.complianceSessionThreshold
			);
			const validateInvestor = ValidateSubmit(investorsDetails);
			if (validateInvestor.length === 0 && anyMajorShareHolder) {
				return;
			} else {
				data.error = validateInvestor;
			}
		}
	};

	const saveSurveyData = async (survey: { data: any; currentPageNo: any }) => {
		const data = survey.data;
		data.pageNo = survey.currentPageNo;
		trackEvents(EVENTS_TRACKING.QUESTIONNAIRE_ANSWER_CHOSEN, {
			timestamp: timestamp,
			...data,
		});

		await postAutoSaveAuestionnaire({
			code: sessionCode,
			type: API_TYPE.AutoSaveQuestionnaire,
			payload: {
				sessionId: sessionDetails?.sessionId,
				nodeId: currentAction._id,
				metadata: data,
				type: 'questionnaire',
			},
		});
	};

	const restoreSurveyData = async () => {
		const resp = await postAutoSaveAuestionnaire({
			code: sessionCode,
			type: API_TYPE.AutoGetQuestionnaire,
			payload: {
				sessionId: sessionDetails?.sessionId,
				nodeId: currentAction._id,
			},
		});
		if (resp?.statusCode === 200) {
			const { metadata } = resp ?? {};
			if (metadata) {
				const data = metadata;
				survey.data = metadata;
				if (data.pageNo) {
					survey.currentPageNo = data.pageNo;
				}
			}
		}
		setPreFillLoad(false);
	};

	// Create a throttled version of saveSurveyData
	const throttledSaveSurveyData = throttle(saveSurveyData, 3000); // 3 seconds delay

	useEffect(() => {
		survey.onCurrentPageChanged.add(saveSurveyData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [survey.onCurrentPageChanged]);

	useEffect(() => {
		survey.onValueChanged.add(throttledSaveSurveyData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [survey.onValueChanged]);

	useEffect(() => {
		restoreSurveyData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		survey.focusFirstQuestionAutomatic = false;
		survey.showCompletedPage = false;
		survey.onComplete.add(() => {
			investerDetailFormPostRequest();
			return false;
		});

		survey.onComplete.add(submitRequest);

		survey.onValidateQuestion.add(questionValidate);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [survey]);

	if (isPreFillLoad) {
		return (
			<div className="terms_header_container--loader">
				<Loader />
			</div>
		);
	}

	return (
		<div
			className={classNames('form-wrapper', {
				'form-wrapper_v2': qrAllowInvite,
			})}
		>
			<div className="terms_header_container">
				<BodyWrapper
					optionalClassName="terms-body-wrapper"
					bodyElement={<Survey model={survey} />}
				/>
			</div>
		</div>
	);
};
