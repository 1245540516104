import { SurveyQuestionElementBase } from 'survey-react-ui';
import { InvestorForm } from './investor-details-form/investor-details-form';

export class SurveyQuestionColorPicker extends SurveyQuestionElementBase {
	constructor(props: any) {
		super(props);
		this.state = { value: this.question.value };
	}
	get question() {
		return this.questionBase;
	}
	get value() {
		return this.question.value;
	}
	get disableAlpha() {
		return this.question.disableAlpha;
	}
	get type() {
		return this.question.colorPickerType;
	}
	handleUpdateInvestorDetails = (data: any) => {
		this.question.value = data;
	};
	// Support the read-only and design modes
	get style() {
		return this.question.getPropertyValue('readOnly') ||
			this.question.isDesignMode
			? { pointerEvents: 'none' }
			: undefined;
	}

	get isReadOnly() {
		return (
			this.question.getPropertyValue('readOnly') ||
			this.question.isDesignMode ||
			this.question.isReadOnly
		);
	}

	renderElement() {
		return (
			<div style={this.style as any}>
				<InvestorForm
					defaultValue={this.question.value}
					updateInvestorCallback={this.handleUpdateInvestorDetails}
					readOnly={this.isReadOnly}
				/>
			</div>
		);
	}
}
