import { useCallback } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { APIS, API_TYPE } from 'constants/api';
import {
	useNetwork,
	useNextStep,
	useSharedVariables,
	useUrl,
	useWebWorker,
	useTokenSession,
} from 'hooks';
import { SignAgreementProviderState } from 'hooks/use-next-step/stores';
import { AccessTokenState } from 'states';
import { isShowSkipState } from 'states/kyc';
import { InviteUserData } from 'states/kyc-verification';
import {
	AllSignAgreementUrlState,
	IsSignAgreementCurrentUrlLoaded,
	SignAgreementUrlState,
} from './states';

export const useSignAgreementRequests = () => {
	const setIsDocumentLoaded = useSetRecoilState(
		IsSignAgreementCurrentUrlLoaded
	);
	const [allSignUrls, setSignUrls] = useRecoilState(AllSignAgreementUrlState);
	const [currentUrl, setCurrentUrl] = useRecoilState(SignAgreementUrlState);
	const provider = useRecoilValue(SignAgreementProviderState);
	const setIsShowSkip = useSetRecoilState(isShowSkipState);
	const inviteUserData = useRecoilValue(InviteUserData);
	const { code: sessionCode } = useRecoilValue(AccessTokenState);
	const { onboardingType, apiEndPoint } = useSharedVariables();

	const {
		handleNext,
		sessionPayloadDetail,
		sessionDetails,
		setSessionDetails,
	} = useNextStep();
	const { accessToken } = useNetwork();
	const { code } = useUrl();
	const { runWorker } = useWebWorker('api-call.js');
	const { postTokenSession } = useTokenSession();

	const { _id, userId, currentAction, stepsId, sessionId } =
		sessionPayloadDetail ?? {};

	const handleWorker = useCallback(
		(result: any) => {
			if (result?.type === 'signAgreement') {
				setSignUrls(result?.data);
				setCurrentUrl({ index: 0, url: result?.data[0]?.url });
			}
		},
		[setCurrentUrl, setSignUrls]
	);

	const requestSignAgreeURLWebWorker = useCallback(() => {
		const info = {
			// /pipelines/request-url` api
			api: `${apiEndPoint}${APIS.TOKEN}`,
			payload: {
				code: sessionCode,
				type: 'requestUrl',
				payload: {
					code: inviteUserData?.code ?? code,
					stepId: 'signAgreement',
				},
			},
			type: 'signAgreement',
			accessToken: accessToken,
		};
		runWorker?.(info, handleWorker);
	}, [
		apiEndPoint,
		code,
		handleWorker,
		inviteUserData?.code,
		runWorker,
		accessToken,
		sessionCode,
	]);

	const submitRequest = useCallback(async () => {
		const payload: any = {
			pipelineId: _id,
			userId,
			actions: [
				{
					id: currentAction?.key,
					data: {
						status: 'completed',
					},
				},
			],
		};
		if (onboardingType === 'complex') {
			payload.nodeId = currentAction._id;
			// COMPLEX_SESSION patch api
			const res = await postTokenSession({ payload, code: sessionCode });
			if (res?.statusCode === 200) {
				const signResponse = { ...res };
				delete signResponse.statusCode;
				setSessionDetails(prev => ({
					...prev,
					nodes: signResponse,
				}));
			} else setIsShowSkip(true);
			return;
		}
		payload.stepId = stepsId;
		const res = await postTokenSession({ payload, code: sessionCode });

		if (res?.statusCode === 200) handleNext();
		else setIsShowSkip(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		_id,
		currentAction._id,
		currentAction?.key,
		onboardingType,
		sessionCode,
		stepsId,
		userId,
	]);

	const checkStatus = useCallback(() => {
		const id = onboardingType === 'complex' ? _id : sessionId;
		const complexDocuSign: string =
			onboardingType === 'complex' && provider === 'docusign'
				? `&nodeId=${sessionDetails?.nodes?._id}`
				: '';
		// TRACK_SESSION_STATUS get api
		const url = `${APIS.TOKEN}?sessionId=${id}&stepId=signAgreement${complexDocuSign}`;
		const payload = {
			code: sessionCode,
			type: API_TYPE.STATUS,
		};
		postTokenSession({ url, ...payload }).then(response => {
			const res = { data: response ?? {} };
			if (onboardingType === 'complex') {
				if (
					response?.statusCode === 200 &&
					res?.data?.[0]?.stepStatus === 'completed'
				) {
					submitRequest();
				}
				return;
			}
			if (res.data?.length) {
				if (
					res.data[0]?.stepStatus !== 'pending' &&
					res.data[0]?.stepStatus !== ''
				) {
					if (currentUrl.index < allSignUrls?.length - 1) {
						setTimeout(() => {
							setCurrentUrl(prev => {
								const prevState = JSON.parse(JSON.stringify(prev));
								prevState['index'] = (prev.index + 1) % allSignUrls.length;
								prevState['url'] = allSignUrls[prevState.index];
								return { ...prevState };
							});
							setIsDocumentLoaded(true);
						}, 1000);
					} else {
						submitRequest();
					}
				}
			}
		});
		return null;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		_id,
		allSignUrls,
		currentUrl.index,
		onboardingType,
		provider,
		sessionCode,
		sessionDetails?.nodes?._id,
		sessionId,
	]);

	const signAgreementRequestURL = useCallback(async () => {
		if (sessionDetails?.steps?.length !== 0) {
			const exists = sessionDetails?.steps?.findIndex(
				(item: any) => item.stepId === 'signAgreement'
			);
			if (exists !== null && exists !== undefined && exists !== -1) {
				const payload = {
					code: inviteUserData?.code ?? code,
					stepId: 'signAgreement',
				};
				// /pipelines/request-url" post api

				const resp = await postTokenSession({
					payload,
					type: API_TYPE.REQUEST_URL,
					code: sessionCode,
				});
				if (resp && resp[0]?.url) {
					setSignUrls(resp);
					setCurrentUrl({ index: 0, url: resp[0]?.url });
				}
			}
		}
	}, [
		sessionDetails?.steps,
		inviteUserData?.code,
		code,
		postTokenSession,
		sessionCode,
		setSignUrls,
		setCurrentUrl,
	]);

	return {
		submitRequest,
		checkStatus,
		signAgreementRequestURL,
		requestSignAgreeURLWebWorker,
	};
};
