import { useCallback, useMemo } from 'react';
import { useNextStep, useTokenSession } from 'hooks';

export const ApplePayCalls = () => {
	const { sessionPayloadDetail } = useNextStep();
	const { postTokenSession } = useTokenSession();

	const { currentAction, investingAmount, sessionId } = useMemo(
		() => sessionPayloadDetail ?? {},
		[sessionPayloadDetail]
	);

	const handleValidMerchantCall = useCallback(
		async (validationUrl: string) => {
			try {
				const response = await postTokenSession({
					payload: {
						nodeId: currentAction._id,
						sessionId: sessionId,
						validationUrl: validationUrl,
						amount: investingAmount,
						action: 'validate-merchant',
						paymentMethod: 'APPLE_PAY',
					},
					nodeId: currentAction._id,
					type: 'payment',
				});
				return response;
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error('Error during merchant validation:', error);
				throw error;
			}
		},
		[currentAction._id, investingAmount, postTokenSession, sessionId]
	);

	const handleOnPaymentAuthorized = useCallback(
		(paymentToken: string) => {
			return postTokenSession({
				payload: {
					nodeId: currentAction._id,
					sessionId: sessionId,
					thirdPartyToken: paymentToken,
					amount: investingAmount,
					action: 'payment-complete',
					paymentMethod: 'APPLE_PAY',
				},
				nodeId: currentAction._id,
				type: 'payment',
			});
		},
		[currentAction._id, investingAmount, postTokenSession, sessionId]
	);

	return { handleValidMerchantCall, handleOnPaymentAuthorized };
};
