import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import Geocode from 'react-geocode';

import { locationCords } from './stores/states';
import { useNotification } from 'hooks/notification';
import { LocationPermissionState } from 'states';
import { LOCATION_MODAL_KEY } from 'views/constants';

/* 
 This hooks help us to get user Location info 
 */
let locationCallCount = 0;
export const useLocation = () => {
	const [locationInfo, setLocationInfo] = useRecoilState(locationCords);
	const [locationPermission, setLocationPermission] = useRecoilState(
		LocationPermissionState
	);
	const { errorNotification } = useNotification();
	const fetchLocation = useCallback(() => {
		return new Promise(resolve => {
			if (!navigator.geolocation) {
				resolve(false);
				return;
			}
			navigator.geolocation.getCurrentPosition(
				position => {
					const { coords } = position;
					if (coords) {
						const locationData = {
							accuracy: coords.accuracy,
							altitude: coords.altitude,
							altitudeAccuracy: coords.altitudeAccuracy,
							latitude: coords.latitude,
							longitude: coords.longitude,
						};

						setLocationInfo(locationData);
						setLocationPermission('closed');
						localStorage.removeItem(LOCATION_MODAL_KEY);
						resolve(locationData);
					} else {
						resolve(false);
					}
				},
				() => {
					if (
						locationPermission === '' &&
						!localStorage.getItem(LOCATION_MODAL_KEY)
					) {
						setLocationPermission('open');
					}
					resolve(false);
				},
				{
					enableHighAccuracy: true,
					timeout: 20000,
					maximumAge: 1000,
				}
			);
		});
	}, [locationPermission, setLocationInfo, setLocationPermission]);
	/*
      Calling this method only if locationinfo does not contain anything ,    
    */
	if (!locationInfo) fetchLocation();

	/* 
    Do not need to return method for that reason we are returing state that we have saved on line 7
    */

	const checkLocationPermission = useCallback(async () => {
		try {
			// Check if the Permissions API is supported
			if (!navigator.permissions) {
				errorNotification(
					'Unable to fetch your location at the moment. Please enter your address manually.'
				);
				return false;
			}

			// Query the geolocation permission state
			const { state } = await navigator.permissions.query({
				name: 'geolocation',
			});

			switch (state) {
				case 'granted':
				case 'prompt':
					return true;

				case 'denied':
					errorNotification(
						'Please enable location permissions to use the "Locate Me" feature!'
					);
					return false;

				default:
					errorNotification(
						'Unable to fetch your location at the moment. Please enter your address manually.'
					);
					return false;
			}
		} catch (error) {
			errorNotification(
				'Unable to fetch your location at the moment. Please enter your address manually.'
			);
			return false;
		}
	}, [errorNotification]);

	const getAddress = useCallback(
		async (onSuccess?: any, locationType: boolean = true) => {
			const checkPermission: any = await checkLocationPermission();
			if (!checkPermission) {
				return;
			}
			const locationData: any = await fetchLocation();
			if (!locationData) {
				errorNotification(
					'Unable to fetch your location at the moment. Please enter your address manually.'
				);
				return;
			}
			Geocode.setApiKey('AIzaSyCTIQp7ZqdF6ls1ze3r9nwXV4lrVWEoH - 8');
			let payload;

			// set response language. Defaults to english.
			Geocode.setLanguage('en');

			// set response region. Its optional.
			// A Geocoding request with region=es (Spain) will return the Spanish city.
			Geocode.setRegion('es');

			// set location_type filter . Its optional.
			// google geocoder returns more that one address for given lat/lng.
			// In some case we need one address as response for which google itself provides a location_type filter.
			// So we can easily parse the result for fetching address components
			// ROOFTOP, RANGE_INTERPOLATED, GEOMETRIC_CENTER, APPROXIMATE are the accepted values.
			// And according to the below google docs in description, ROOFTOP param returns the most accurate result.
			Geocode.setLocationType(locationType ? 'ROOFTOP' : '');

			// Enable or disable logs. Its optional.
			Geocode.enableDebug();

			// Get formatted address, city, state, country from latitude & longitude when
			// Geocode.setLocationType("ROOFTOP") enabled
			// the below parser will work for most of the countries
			Geocode.fromLatLng(
				locationData?.latitude?.toString(),
				locationData?.longitude?.toString()
			).then(
				response => {
					let city,
						state,
						country,
						postal_code,
						route,
						sublocality,
						village,
						countryShortName;
					if (!response?.results?.length) {
						if (locationCallCount === 4) {
							errorNotification(
								'Something went wrong. Please enter your address manually.'
							);
						} else {
							getAddress(onSuccess, false);
							locationCallCount += 1;
						}
					}
					const address = response.results[0].address_components;
					for (let i = 0; i < address.length; i++) {
						for (let j = 0; j < address[i].types.length; j++) {
							switch (address[i].types[j]) {
								case 'route':
									route = address[i].long_name;
									break;
								case 'sublocality':
									sublocality = address[i].long_name;
									break;
								case 'neighborhood':
									village = address[i].long_name;
									break;
								case 'locality':
									city = address[i]?.long_name;
									break;
								case 'administrative_area_level_1':
									state = address[i].long_name;
									break;
								case 'country':
									country = address[i]?.long_name;
									countryShortName = address[i]?.short_name;

									break;
								case 'postal_code':
									postal_code = address[i]?.long_name;
									break;
							}
						}
					}

					payload = {
						city,
						state,
						country,
						postal_code,
						sublocality,
						route,
						village,
						countryShortName,
					};

					onSuccess?.(payload);

					return payload;
				},
				error => {
					// eslint-disable-next-line no-console
					console.error(error);
					if (/ZERO_RESULTS/.test(error)) {
						getAddress(onSuccess, false);
						return;
					}
					errorNotification(
						'Something went wrong. Please enter your address manually.'
					);
				}
			);

			return payload;
		},
		[checkLocationPermission, errorNotification, fetchLocation]
	);

	return { locationInfo, fetchLocation, getAddress };
};
