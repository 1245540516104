import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { API_TYPE } from 'constants/api';
import { useNotification, useSharedVariables, useTokenSession } from 'hooks';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import {
	AccessTokenState,
	InviteUserData,
	WebComponentMetaDataState,
} from 'states';
import { isMobileDevice } from 'utils';
import { AppleResponseToken, FlowTypeState, SessionExistState } from './states';
import { IinviteUserPayload } from './types';
import {
	PREVENTAPPLE_LOGIN_FOR_URLS,
	isSafariBroswerDetect,
} from 'views/term-condition/constant';

export const useBasicInformation = () => {
	const [isLoaded, setIsLoaded] = useState(true);
	const [flowType, setFlowType] = useRecoilState(FlowTypeState);
	const { apiEndPoint: API_HOST } = useSharedVariables();

	const [isSessionExist, setIsSessionExist] = useRecoilState(SessionExistState);
	const setAccessTokenDetails = useSetRecoilState(AccessTokenState);

	const [inviteUserData, setInviteUserData] = useRecoilState(InviteUserData);
	const { code: qrId } = useRecoilValue(AccessTokenState);
	const appleResponseToken = useRecoilValue(AppleResponseToken);
	const isMobile = isMobileDevice();

	const { errorNotification } = useNotification();
	const { postTokenSession } = useTokenSession();
	const { windowReload, qrAllowInvite, paramSessionId } = useSharedVariables();

	const navigate = useNavigate();
	const { isSkipNewSession } = useRecoilValue(WebComponentMetaDataState);

	const constructNewUrl = (
		originalUrl: string,
		newUrl: string,
		paramToRemove: string
	) => {
		const originalUrlObj = new URL(originalUrl);
		const newUrlObj = new URL(newUrl);

		// Remove the specified parameter from the original URL
		originalUrlObj.searchParams.delete(paramToRemove);

		// Copy parameters from the original URL to the new URL
		originalUrlObj.searchParams.forEach((value, key) => {
			if (!newUrlObj.searchParams.has(key)) {
				newUrlObj.searchParams.append(key, value);
			}
		});

		return newUrlObj.toString();
	};

	const getComplexSession = useCallback(
		async ({ code, token, type }: any) => {
			if (paramSessionId) {
				const url = constructNewUrl(
					window.location.href,
					window.location.origin +
						`/${code}?type=complex&session=complexSession`,
					'type'
				);
				setTimeout(() => {
					window.location.href = url;
				}, 1000);
			} else {
				setAccessTokenDetails({
					code,
					token,
					type,
				});
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const getSessionNewFlow = useCallback(
		async ({ code, token, type }: any) => {
			if (flowType === 'complex') {
				getComplexSession({ code, token, type });
				return;
			}
			if (paramSessionId) {
				navigate(`/${code}?session=linearSession`);
				windowReload();
			} else {
				setAccessTokenDetails({
					code,
					token,
					type,
				});
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[flowType, inviteUserData.code, inviteUserData.session]
	);

	const getSession = useCallback(
		async ({ code, token, type }: any) => {
			getSessionNewFlow({ code, token, type });
			return null;
		},
		[getSessionNewFlow]
	);

	const continueInvite = useCallback(async () => {
		setIsLoaded(true);
		if (inviteUserData.code && inviteUserData.session) {
			await getSession(inviteUserData);
		}
		setIsLoaded(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inviteUserData]);

	const inviteUser = useCallback(
		async (payload: IinviteUserPayload) => {
			setIsLoaded(false);
			const customConfig = {
				headers: {
					allowfreeinvites: 'yes',
				},
			};
			const allowCheck = qrAllowInvite ? customConfig : {};
			const resp = await postTokenSession({
				code: qrId,
				type: API_TYPE.QR_INVITE,
				payload,
				apiHost: API_HOST,
				customConfig: allowCheck,
			});
			const apiData = resp?.[0] ?? resp;
			const { code, session, type, token } = apiData ?? {};
			if (code) {
				if (
					(isMobile || isSafariBroswerDetect) &&
					!PREVENTAPPLE_LOGIN_FOR_URLS.includes(window.location.host)
				) {
					const customConfig = {
						headers: {
							Authorization: `Bearer ${token}`,
						},
					};
					const payload = {
						userId: apiData?.userId ?? '',
						token: {
							response: { ...appleResponseToken },
						},
						type,
					};
					await postTokenSession({
						code,
						payload,
						type: 'device-tokens',
						customConfig,
					});
				}

				const { code: alreadyExistCode, _id: alreadyExistSession } = resp;
				if (!isSkipNewSession && alreadyExistCode && alreadyExistSession) {
					setFlowType(type);
					setIsSessionExist(true);
					setInviteUserData({
						code: alreadyExistCode,
						session: alreadyExistSession,
					});
				} else if (session || isSkipNewSession) {
					setIsSessionExist(false);
					setInviteUserData(apiData);
					if (type === 'complex') {
						await getComplexSession({ code, token, type });
					} else {
						await getSession({ code, token, type });
					}
				}
				setIsLoaded(true);
				return apiData;
			}
			setIsLoaded(true);
			errorNotification(resp?.message ?? 'Failed to invite try again later.');
		},
		[
			qrAllowInvite,
			postTokenSession,
			qrId,
			API_HOST,
			errorNotification,
			isMobile,
			isSkipNewSession,
			appleResponseToken,
			setFlowType,
			setIsSessionExist,
			setInviteUserData,
			getComplexSession,
			getSession,
		]
	);

	const abortInvite = useCallback(
		async (payload: any) => {
			if (inviteUserData.code && inviteUserData.session) {
				const resp = await postTokenSession({
					code: qrId,
					type: API_TYPE.ABORT,
					payload: {
						isAborted: true,
						type: flowType,
						session: inviteUserData.session,
					},
				});
				if (resp.statusCode === 200) {
					inviteUser(payload);
				}
			}
			setIsLoaded(true);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[inviteUserData.code, flowType, inviteUserData.session]
	);

	return {
		inviteUser,
		isLoaded,
		isSessionExist,
		continueInvite,
		abortInvite,
		setIsSessionExist,
	};
};
