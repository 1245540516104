import { useSharedVariables } from 'hooks/shared-variable';
import { useTokenSession } from 'hooks/token';
import { useState } from 'react';
import { getOrigin } from 'utils';

type FetchDataResponse = {
	response: any;
	loading: boolean;
	fetchData: any;
};

const useFetchWithToken = (): FetchDataResponse => {
	const [response, setResponse] = useState<any>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const { apiEndPoint: API_HOST } = useSharedVariables();
	const { getTokenSession } = useTokenSession();

	const fetchData = async (url: string, token: string): Promise<void> => {
		setLoading(true);
		try {
			const resp = await getTokenSession({
				url,
				apiHost: API_HOST,
				customConfig: {
					headers: {
						Authorization: `Bearer ${token}`,
						Domain: getOrigin() ?? '',
					},
				},
			});

			setResponse(resp);
			return resp;
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log('error', error);
		} finally {
			setLoading(false);
		}
	};

	return { response, loading, fetchData };
};

export default useFetchWithToken;
