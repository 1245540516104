import { usePlacesWidget } from 'react-google-autocomplete';
import { PREVENT_SKIP_AND_LOCATEME_URLS } from 'views/constants';
import './auto-fill-input-location.scss';
import { Input, ReactSwitch } from '@storybook';
import { useCallback, useState } from 'react';
import { Fragment } from 'react';
import classNames from 'classnames';

export const AutoFillLocation = ({
	handleOnClickLocate,
	onSuccess,
	disabled = false,
	isLocate = true,
	placeHolder = 'Enter location here',
	onSearch,
	onClear,
	name,
	value,
	handleOnChange,
	isRequired = false,
	handleChangeText = () => {},
	isManualSearch = false,
	noLabel = false,
	isLocateLoaded = false,
	optionalClass = '',
}: any) => {
	const [isAutoSearch, setAutoSearch] = useState(true);

	const { ref } = usePlacesWidget({
		apiKey: 'AIzaSyCTIQp7ZqdF6ls1ze3r9nwXV4lrVWEoH-8',
		onPlaceSelected: place => {
			let city,
				state,
				country,
				postal_code,
				route,
				sublocality,
				village,
				street_number;

			for (let i = 0; i < place?.address_components.length; i++) {
				for (let j = 0; j < place?.address_components[i]?.types?.length; j++) {
					const { long_name, short_name } =
						place?.address_components?.[i] ?? {};
					switch (place?.address_components[i].types[j]) {
						case 'route':
							route = long_name;
							break;
						case 'street_number':
							street_number = long_name;
							break;
						case 'sublocality':
							sublocality = long_name;
							break;
						case 'neighborhood':
							village = long_name;
							break;
						case 'locality':
							city = long_name;
							break;
						case 'administrative_area_level_1':
							state = short_name ?? long_name;
							break;
						case 'country':
							country = long_name;
							break;
						case 'postal_code':
							postal_code = long_name;
							break;
					}
				}
			}

			const payload = {
				city: city ?? '',
				state: state ?? '',
				country: country ?? '',
				postal_code: postal_code ?? '',
				street_number: street_number ?? '',
				sublocality: sublocality ?? '',
				route: route ?? '',
				village: village ?? '',
				enteredAddress: (ref.current as any)?.value ?? '',
			};
			onSuccess?.(payload);
		},
		options: {
			types: ['geocode', 'establishment'],
		},
	});

	const handleOnClear = () => {
		if (ref.current) (ref.current as any).value = '';
		onClear?.();
	};

	const handleToggle = useCallback((checked: boolean) => {
		setAutoSearch(checked);
	}, []);

	const props = {
		...(name && { name: name }),
		...(value && { value: value }),
	};

	const locateMeClass = classNames(
		'auto-fill-wrapper__label-wrapper__locate-me-wrapper',
		{
			'auto-fill-wrapper__label-wrapper__disabled-locate-btn': disabled,
		}
	);

	return (
		<Fragment>
			<div className={`auto-fill-wrapper ${optionalClass}`}>
				<div className="auto-fill-wrapper__label-wrapper">
					<div className="auto-fill-wrapper__label-wrapper__label">
						{!noLabel && placeHolder}
						{isRequired && <span>*</span>}
					</div>

					{isLocate &&
						!PREVENT_SKIP_AND_LOCATEME_URLS.includes(document.referrer) && (
							<div className={locateMeClass}>
								<i className="ri-map-pin-line"></i>
								{!isLocateLoaded ? (
									<div
										onClick={() => {
											handleOnClickLocate();
											if ((ref as any)?.current) {
												(ref as any).current.value = '';
											}
										}}
										className="auto-fill-wrapper__label-wrapper__locate-me-wrapper__label"
									>
										Locate me
									</div>
								) : (
									<div className="auto-fill-wrapper__label-wrapper__locate-me-wrapper__label">
										Loading...
									</div>
								)}
							</div>
						)}

					{isManualSearch && (
						<div className="auto-fill-wrapper--manual-search">
							<ReactSwitch
								checked={isAutoSearch}
								handleChange={handleToggle}
								id={'complex-flow'}
							/>
							<label className="auto-fill-wrapper__label-wrapper__label">
								Autosearch
							</label>
						</div>
					)}
				</div>

				<div
					className={`auto-fill-wrapper__input-wrapper ${isAutoSearch ? 'auto-fill-wrapper--address-visible' : 'auto-fill-wrapper--address-hide'}`}
				>
					<input
						style={{ width: '100%', minHeight: '48px' }}
						placeholder={placeHolder}
						ref={ref as any}
						disabled={disabled}
						{...props}
						onChange={handleOnChange}
						autoComplete="off"
					/>
					{onSearch && <i className="ri-search-line search-icon"></i>}
					{onClear && (
						<i onClick={handleOnClear} className="ri-close-circle-line"></i>
					)}
				</div>

				{!isAutoSearch && (
					<Input
						isRequired
						label=""
						placeholder={
							placeHolder === 'Business Street' ? placeHolder : 'Street'
						}
						inputType="text"
						handleChange={handleChangeText}
						inputName={name}
						value={value}
					/>
				)}
			</div>
		</Fragment>
	);
};
