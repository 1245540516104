// Titles and descriptions for the QR success header based on completion status
export const QrSuccessHeader = {
	auth: {
		close: {
			title: 'Verification complete',
			description: 'Your verification has been completed successfully',
			loadingMessage: '',
			image: 'success.gif',
		},
		redirect: {
			title: 'Verification complete',
			description: 'Your verification is now complete.',
			loadingMessage:
				'Redirecting you to the next step in the onboarding process...',
			image: 'success.gif',
		},
		reload: {
			title: 'Oops! something went wrong',
			description:
				'You can reload this page to finish the rest of the onboarding process.',
			loadingMessage: '',
			image: 'webgl-error.svg',
		},
		'in-process': {
			title: 'Verification already in process',
			description:
				"A verification is already underway. A new verification can't be started.",
			loadingMessage: '',
			image: 'webgl-error.svg',
		},
		terminated: {
			title: 'Link Expired',
			description:
				'The QR code link has expired or the qr session has been terminated. Please scan the QR code again to proceed.',
			loadingMessage: '',
			image: 'webgl-error.svg',
		},
	},
	kyc: {
		close: {
			title: 'Return to desktop',
			description: 'Please complete the remaining process on your desktop.',
			loadingMessage: '',
			image: '',
			icon: 'ri-error-warning-fill',
		},
		redirect: {
			title: 'Identity verification complete',
			description: 'Your identity verification is now complete.',
			loadingMessage:
				'Redirecting you to the next step in the onboarding process...',
			image: 'success.gif',
		},
		reload: {
			title: 'Oops! something went wrong',
			description:
				'You can reload this page to finish the rest of the onboarding process.',
			loadingMessage: '',
			image: 'webgl-error.svg',
		},
		'in-process': {
			title: 'Kyc already in process',
			description:
				"A KYC verification is already underway. A new KYC process can't be started.",
			loadingMessage: '',
			image: 'webgl-error.svg',
		},
		terminated: {
			title: 'Link Expired',
			description:
				'The QR code link has expired or the session has been terminated. Please scan the QR code again to proceed.',
			loadingMessage: '',
			image: 'webgl-error.svg',
		},
	},
};

// Loading message displayed while identity verification is in progress on the mobile device
export const QrLoadingMessage = {
	auth: "Your verification is being completed on your mobile device. Once finished, you'll be redirected automatically.",
	kyc: "Your identity verification is being completed on your mobile device. Once finished, you'll be redirected automatically.",
};

// URL of the Simplici logo, used as a central image in the QR code
export const SimpliciLogo2 =
	'https://storage.googleapis.com/satschel-public-assets/images/logo/simplici-icon.png';

// Defines the different roles a user can have in the QR process.
export enum QrUser {
	Primary = 'primary', // Primary user initiating or managing the process.
	Secondary = 'secondary', // Secondary user joining the process, typically for validation or support.
}

// Enum for QR verification types
export enum QrVerification {
	Auth = 'auth', // Represents the authentication process
	Kyc = 'kyc', // Represents the Know Your Customer (KYC) process
}

// Represents the possible statuses within the QR session.
export enum QrStatus {
	KYC_COMPLETE = 'qr-kyc-completed', // Indicates the process has been completed successfully.
	TERMINATED = 'qr-session-terminated', // Indicates the session has been terminated (manually or due to an error).
	QR_SESSION_FULL = 'qr-session-full', // Signals that the session cannot accept additional users (session is at capacity).
	SECONDARY_CONNECTED = 'secondary-connected', // Indicates that a secondary user has successfully connected to the session.
	PRIMARY_CONNECTED = 'primary-connected', // Indicates that the primary user has successfully connected to the session.
	SECONDARY_DISCONNECTED = 'secondary-disconnected', // Signals that the secondary user has disconnected from the session.
}
// Object holding QR verification details for 'auth' and 'kyc'
export const QrDetails = {
	// Authentication process details
	auth: {
		title: 'Complete verification on your smartphone', // Title for the authentication process
		description: `Please complete verification on your smartphone to capture
	  a high-quality photo of face and reduce the
	  chance of errors.`, // Description of the process
		steps: [
			{ value: 'On your smartphone, open the camera and scan the QR code.' },
			{ value: 'Click on "Begin Liveness Verification".' },
			{ value: 'Turn your head slowly to the right, and then to the left.' },
			{
				value:
					'Align your face in the square. Look forward and take a selfie then click on "Capture".',
			},
			{
				value:
					'Take a moment to review it. If your face is not clearly visible then feel free to retake another picture.',
			},
			{ value: 'If your face is clearly visible then click on "Submit"' },
		], // List of steps for facial enrollment
		tips: [
			{ value: 'Find a well-lit area for accurate image capture.' },
			{ value: 'Turn your head slowly to the right, and then to the left.' },
			{ value: 'Position your face within the oval boundary' },
			{ value: 'Keep your face straight for a frontal face image capture.' },
		], // Tips for successful facial verification
	},

	// KYC process details
	kyc: {
		title: 'Complete identity verification on your smartphone', // Title for the KYC process
		description: `Please complete identity verification on your smartphone to capture
	  a high-quality photo of your government-issued ID and reduce the
	  chance of errors.`, // Description of the KYC process
		steps: [
			{ value: 'On your smartphone, open the camera and scan the QR code.' },
			{ value: 'Select document issuing country.' },
			{ value: 'Choose a government-Issued photo ID.' },
			{ value: 'Take a photo of your government-issued ID.' },
			{ value: 'Submit' },
		], // List of steps for KYC identity verification
		tips: [
			{ value: 'Place the document in a well-lit area.' },
			{ value: 'Avoid direct light sources that may cause glare.' },
			{ value: 'Ensure the document is flat and not tilted.' },
		], // Tips for capturing the ID image correctly
	},
};

export const QrDimensions = {
	auth: {
		size: 200,
		logoSize: 50,
	},
	kyc: {
		size: 200,
		logoSize: 50,
	},
};
