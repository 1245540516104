import {
	AutoFillLocation,
	Button,
	Input,
	Loader,
	ReactDropdown,
} from '@storybook';
import {
	ChangeEvent,
	Fragment,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import COUNTRIES from '@storybook/country-code/json/country-codes.json';
import { BodyWrapper, LabelElement } from 'components';

import { useLocation, useSharedVariables } from 'hooks';

import {
	AllCountryState,
	IAutoFillLocation,
	ICountries,
	KYCAddressInfo,
	SelectedCountryState,
	SelectedDefaultCountryState,
	useKycRequests,
} from 'views/kyc/stores';
import { ADDRESS_DETAILS_FORM } from 'views/kyc/constants';

import './address-details.scss';
import { THE_KYB_PROVIDER_TOKEN } from 'views/kyb/constants';
import useFetchWithToken from 'hooks/use-fetch-with-token/use-fetch-with-token';
import { APIS } from 'constants/api';
import { formatValue } from 'utils';
import { CountryStateListState, TheKYBStateListState } from 'views/kyb/stores';

export const AddressDetails = () => {
	const [countries, setCountries] = useRecoilState(AllCountryState);
	const [isLocateLoaded, setLocateLoaded] = useState(false);
	const [locationData, setLocationData] = useState<IAutoFillLocation>({
		city: '',
		state: '',
		country: '',
		postal_code: '',
		street_number: '',
		sublocality: '',
		route: '',
		village: '',
		enteredAddress: '',
	});
	const [isLoading, setIsLoading] = useState(false);

	const setSelectedCountry = useSetRecoilState(SelectedCountryState);
	const [statesList, setStateList] = useRecoilState(TheKYBStateListState);
	const [defaultCountry, setDefaultCountry] = useRecoilState(
		SelectedDefaultCountryState
	);

	const [addressDetails, setAddressDetails] = useRecoilState(KYCAddressInfo);
	const [countryListResp, setCountryListResp] = useRecoilState(
		CountryStateListState
	);
	//hooks
	const { submitKycVerification } = useKycRequests();
	const { getAddress } = useLocation();
	const { envHost } = useSharedVariables();
	const { fetchData } = useFetchWithToken();

	const removeComma = useCallback((data: string) => {
		data = data?.replace(/^,*/, '');
		data = data?.replace(/,*$/, '');
		return data;
	}, []);

	const handleSetAddressDetails = useCallback(
		({
			route = '',
			village = '',
			sublocality = '',
			postal_code = '',
			country = '',
			state = '',
			city = '',
		}) => {
			const selectedCountry = countryListResp?.find(
				countryData =>
					countryData?.name?.toLowerCase() === country?.toLowerCase() ||
					countryData?.iso2?.toLowerCase() === country?.toLowerCase() ||
					countryData?.iso3?.toLowerCase() === country?.toLowerCase()
			);
			const selectedState = selectedCountry?.states?.find(
				stateData =>
					stateData?.name?.toLowerCase() === state?.toLowerCase() ||
					stateData?.state_code?.toLowerCase() === state?.toLowerCase()
			);
			const stateLists = (selectedCountry?.states ?? []).map(state => ({
				label: formatValue(state?.name ?? '--'),
				value: state?.name,
			}));
			const payload = {
				streetAddress: removeComma(
					`${route},${village},${sublocality},${city},`
				),
				building: (route + ' ' + sublocality)?.trim(),
				zipCode: postal_code,
				city: city,
				state: selectedState?.name ?? '',
				country: selectedCountry?.name ?? '',
			};
			setAddressDetails(payload);
			setStateList(stateLists);
		},
		[countryListResp, removeComma, setAddressDetails, setStateList]
	);

	/**
	 * Checks if any value in the location data is non-empty.
	 * @returns {boolean} - True if any value is non-empty, false otherwise.
	 */
	const hasNonEmptyValues = (data: IAutoFillLocation) => {
		// Returns true if at least one value is not an empty string
		return Object.values(data).some(value => value !== '');
	};

	useEffect(() => {
		// Check if locationData has keys and contains at least one non-empty value
		if (Object.keys(locationData).length && hasNonEmptyValues(locationData)) {
			handleSetAddressDetails(locationData);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [locationData]);

	const handleChangeCountry = useCallback(
		(event: ICountries, name: string) => {
			if (name === 'country') {
				const selectedCountry = countryListResp?.find(
					country => country?.name === event.value
				);
				const stateLists = (selectedCountry?.states ?? []).map(state => ({
					label: formatValue(state?.name ?? '--'),
					value: state?.name,
				}));
				setSelectedCountry(event);
				setStateList(stateLists);
				setAddressDetails({
					...addressDetails,
					country: event.value,
					state: '',
				});
			} else if (name === 'state') {
				setAddressDetails({ ...addressDetails, state: event.value });
			}
		},
		[
			addressDetails,
			countryListResp,
			setAddressDetails,
			setSelectedCountry,
			setStateList,
		]
	);

	const handleChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			const { value, name } = e.target;
			const fieldsWithMaxLengthFifty = ['country', 'city'];

			// Handle zipCode validation
			if (name === 'zipCode' && (value === '' || value.length < 10)) {
				setAddressDetails({ ...addressDetails, [name]: value });
				return;
			}

			// Handle country, state, and city validation
			if (fieldsWithMaxLengthFifty.includes(name) && value.length <= 50) {
				setAddressDetails({ ...addressDetails, [name]: value });
				return;
			}

			// Handle all other fields without specific validation
			if (!fieldsWithMaxLengthFifty.includes(name) && name !== 'zipCode') {
				setAddressDetails({ ...addressDetails, [name]: value });
			}
		},
		[addressDetails, setAddressDetails]
	);

	const handleSubmit = useCallback(async () => {
		setIsLoading(true);
		await submitKycVerification();
		setIsLoading(false);
	}, [submitKycVerification]);

	const updateLocation = useCallback(
		(data: any) => {
			setLocationData(data);
			const findCountry = COUNTRIES?.find(
				item => item.value?.toLowerCase() === data?.country?.toLowerCase()
			);
			if (findCountry) {
				const payload = {
					label: findCountry?.value,
					value: findCountry?.value,
				};
				setSelectedCountry(payload);
				setDefaultCountry(payload);
			} else {
				setSelectedCountry({});
				setDefaultCountry({});
			}
		},
		[setDefaultCountry, setSelectedCountry]
	);

	const handleCurrentLocation = useCallback(async () => {
		setLocateLoaded(true);
		await getAddress(updateLocation);
		setLocateLoaded(false);
	}, [getAddress, updateLocation]);

	const isEmpty = useCallback((str: string | undefined) => {
		if (str) {
			const data = str?.trim();
			if (data) return data;
		}
		return null;
	}, []);

	const isDisabled = useMemo(() => {
		const { streetAddress, zipCode, city, state, country } =
			addressDetails ?? {};
		if (
			isEmpty(streetAddress) &&
			isEmpty(zipCode) &&
			isEmpty(city) &&
			isEmpty(state) &&
			isEmpty(country)
		) {
			return false;
		}
		return true;
	}, [addressDetails, isEmpty]);

	const setFormatedCountryData = useCallback((data: any) => {
		const countryList = data.map((country: { name: string }) => ({
			label: formatValue(country.name ?? '--'),
			value: country.name,
		}));
		setCountries(countryList);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const fetchDataFromAPI = async () => {
			try {
				const response = await fetchData(
					APIS.COUNTRY_LIST,
					THE_KYB_PROVIDER_TOKEN[envHost as keyof typeof THE_KYB_PROVIDER_TOKEN]
				);

				if (response?.message === 'ok') {
					const { data } = response;
					setCountryListResp(data);
					setFormatedCountryData(data);
				}

				// Do something with the response
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error('Error fetching data:', error);
			}
		};
		if (countryListResp?.length === 0) {
			fetchDataFromAPI();
		} else {
			setFormatedCountryData(countryListResp);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [countryListResp]);

	const getOption = useCallback(
		(name: string) => {
			if (name === 'country') {
				return countries;
			} else return statesList;
		},
		[countries, statesList]
	);

	const getValue = useCallback(
		(name: string) => {
			if (!addressDetails[name]) {
				return { label: 'Select', value: '' };
			}
			return {
				label: (addressDetails[name] ?? 'Select')
					.replace(/_/g, ' ')
					.toLowerCase()
					.replace(/\b\w/g, (char: string) => char.toUpperCase()),
				value: addressDetails[name] ?? '',
			};
		},
		[addressDetails]
	);

	const bodyContent = useMemo(() => {
		return (
			<>
				<div className="input-wrapper-inner">
					<div className="input-wrapper--form">
						<AutoFillLocation
							handleOnClickLocate={handleCurrentLocation}
							onSuccess={updateLocation}
							disabled={false}
							isLocateLoaded={isLocateLoaded}
							optionalClass="input-wrapper--col-1"
						/>
						<div className="input-wrapper-inner--divider">
							<span>OR</span>
						</div>
						{ADDRESS_DETAILS_FORM.map((f, i) => (
							<Fragment key={`${f.name + i}`}>
								{(f.type === 'text' || f.type === 'number') && (
									<Input
										handleChange={handleChange}
										value={addressDetails[f.name] ?? ''}
										placeholder={f.placeholder}
										inputType={f.type as any}
										label={f.label}
										inputName={f.name}
										isRequired={f.isRequired}
										disabled={false}
										isError={addressDetails[f?.name]?.length === 0}
										errorMessage={`${f.label} can't be empty.`}
									/>
								)}
								{f.type === 'dropdown' && (
									<div className="dropdown-wrapper">
										<ReactDropdown
											options={getOption(f.name)}
											value={getValue(f.name)}
											defaultValue={defaultCountry}
											placeholder="Country"
											handleChangeSelect={e => handleChangeCountry(e, f.name)}
											isDisabled={false}
											isSearchable
											isRequired={f.isRequired}
											label={f.label}
											isError={
												!Object.values(locationData).every(
													value => value === ''
												) && getValue(f.name).value?.length === 0
											}
											errorMessage={`${f.label} can't be empty.`}
										/>
									</div>
								)}
								{f.type === 'stateSelect' && addressDetails?.country && (
									<ReactDropdown
										options={getOption(f.name)}
										handleChangeSelect={e => handleChangeCountry(e, f.name)}
										isSearchable={true}
										label={f.name}
										value={getValue(f.name)}
										optionsDropHeight={230}
										placeholder={f.name}
										isDisabled={false}
										key={f.name}
										isRequired
										isError={
											!Object.values(locationData).every(
												value => value === ''
											) && getValue(f.name).value?.length === 0
										}
										errorMessage={`${f.label} can't be empty.`}
									/>
								)}
							</Fragment>
						))}
					</div>
				</div>
				<div className="addres-identity-btn">
					<Button
						type="button__filled button__filled--primary button__large"
						label={'Submit'}
						handleClick={handleSubmit}
						disabled={isDisabled}
					/>
				</div>
			</>
		);
	}, [
		addressDetails,
		defaultCountry,
		getOption,
		getValue,
		handleChange,
		handleChangeCountry,
		handleCurrentLocation,
		handleSubmit,
		isDisabled,
		isLocateLoaded,
		locationData,
		updateLocation,
	]);

	const labelElement = useMemo(() => {
		return <LabelElement text="Identity Verification" />;
	}, []);

	const headerElement = useMemo(() => {
		return (
			<div className="kyc-address-details__header">
				<div className="kyc-address-details__header__title">
					Please provide your address details
				</div>
				<div className="kyc-address-details__header__sub-title">
					We need some more information to validate your KYC.
				</div>
			</div>
		);
	}, []);

	return isLoading ? (
		<div className="kyc-loader-wrapper">
			<Loader dimension={40} className="loader-blue" />
		</div>
	) : (
		<BodyWrapper
			headerElement={headerElement}
			label={labelElement}
			bodyElement={bodyContent}
		/>
	);
};
