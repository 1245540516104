import { useCallback, useMemo } from 'react';
import { useNextStep, useNotification, useTokenSession } from 'hooks';
import { ApplePayCalls } from './hook';
import { useRecoilValue } from 'recoil';
import { AccessTokenState } from 'states';
import { ApplePayPayment, ApplePayPaymentRequest } from './type';

const useApplePay = () => {
	// Hooks
	const {
		sessionDetails,
		sessionPayloadDetail,
		setSessionDetails,
		handleNext,
	} = useNextStep();
	const { errorNotification, warningNotification, successNotification } =
		useNotification();
	const { handleValidMerchantCall, handleOnPaymentAuthorized } =
		ApplePayCalls();

	const { investingAmount } = sessionDetails ?? {};
	const { postTokenSession } = useTokenSession();
	const { code: sessionCode } = useRecoilValue(AccessTokenState);

	const { currentAction } = useMemo(
		() => sessionPayloadDetail ?? {},
		[sessionPayloadDetail]
	);

	const finishPayment = useCallback(async () => {
		const payload = {
			nodeId: currentAction._id,
			actions: [{}],
		};
		// COMPLEX_SESSION patch api
		const res = await postTokenSession({ payload, code: sessionCode });
		if (res?.statusCode === 200) {
			const paymentResponse = { ...res };
			delete paymentResponse.statusCode;
			setSessionDetails(prev => ({
				...prev,
				nodes: paymentResponse,
			}));
			handleNext();
		} else {
			errorNotification(res.message ?? 'Something Went Wrong');
		}
	}, [
		currentAction._id,
		errorNotification,
		handleNext,
		postTokenSession,
		sessionCode,
		setSessionDetails,
	]);

	const handleApplePayClick = useCallback(() => {
		if (!window.ApplePaySession) {
			errorNotification('Apple Pay is not supported on this device.');
			return;
		}

		// if (/Android/i.test(navigator.userAgent)) {
		// 	errorNotification('Apple Pay is not supported.');
		// 	return;
		// }

		const paymentRequest: ApplePayPaymentRequest = {
			countryCode: 'US',
			currencyCode: 'USD',
			merchantCapabilities: ['supports3DS'],
			supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
			total: { label: 'your payment', amount: investingAmount },
			requiredBillingContactFields: ['postalAddress'],
		};

		const session = new ApplePaySession(6, paymentRequest);
		session.oncancel = () => {
			errorNotification('Transaction Failed.');
		};
		session.onvalidatemerchant = async (event: { validationURL: string }) => {
			const resp = await handleValidMerchantCall(event.validationURL);
			if (resp?.session_details)
				session.completeMerchantValidation(JSON.parse(resp.session_details));
		};

		session.onpaymentauthorized = async (event: {
			payment: ApplePayPayment;
		}) => {
			const paymentToken = event?.payment;

			if (paymentToken) {
				const paymentTokenString = JSON.stringify(paymentToken);
				const resp = await handleOnPaymentAuthorized(paymentTokenString);
				if (resp?.statusCode === 200 || /\bhold\b/.test(resp?.message ?? '')) {
					session.completePayment(0);
					if (/\bhold\b/.test(resp?.message)) {
						warningNotification(resp?.message);
					} else {
						successNotification('Transaction successfully done !');
					}
					finishPayment();
				} else {
					session.completePayment(1);
				}
			}
		};

		session.begin();
	}, [
		errorNotification,
		finishPayment,
		handleOnPaymentAuthorized,
		handleValidMerchantCall,
		investingAmount,
		successNotification,
		warningNotification,
	]);

	return { handleApplePayClick };
};

export default useApplePay;
