import { FC, ReactNode, useMemo, useState, Fragment, useCallback } from 'react';
import Select, { StylesConfig } from 'react-select';
import Popup from 'rmc-picker/es/Popup';
import RMCPicker from 'rmc-picker/es/Picker';
import { Input } from '@storybook/input';
import { isEmptyArrayObject, isMobileDevice } from 'utils';

import 'rmc-picker/assets/index.css';
import 'rmc-picker/assets/popup.css';
import './dropdown.scss';
import { v4 } from 'uuid';

type IMenuType = 'auto' | 'top' | 'bottom';
export type IOption = { label: string; value: string };

interface IReactDropdown {
	handleChangeSelect?: (event: IOption) => void;
	label?: string;
	options: IOption[];
	value: IOption | null;
	defaultValue?: IOption;
	isSearchable?: boolean;
	isMulti?: boolean;
	placeholder?: string | ReactNode;
	optionsDropHeight?: number;
	maxMenuHeight?: number;
	isRequired?: boolean;
	isDisabled?: boolean;
	dropDownStyle?: React.CSSProperties;
	menuPlacement?: 'auto' | 'bottom' | 'top';
	optionDropDownStyle?: any;
	containerWidth?: string; // New prop for container width
	getCustomOptions?: any;
	isError?: boolean;
	errorMessage?: string;
}

export const ReactDropdown: FC<IReactDropdown> = ({
	handleChangeSelect,
	options = [],
	value,
	defaultValue,
	isSearchable = false,
	isMulti = false,
	placeholder,
	optionsDropHeight = undefined,
	maxMenuHeight = undefined,
	label = '',
	isRequired = false,
	isDisabled = false,
	dropDownStyle,
	menuPlacement = 'auto', // default value
	optionDropDownStyle = {},
	containerWidth,
	getCustomOptions,
	isError,
	errorMessage,
}) => {
	const [popupVisible, setPopupVisible] = useState(false);
	const [tempValue, setTempValue] = useState(value);
	const [searchTerm, setSearchTerm] = useState('');
	const [filteredOptions, setFilteredOptions] = useState<IOption[]>(options);

	const handleSearch = useCallback(
		(value: string) => {
			const optionsList = options.filter(option =>
				option.label.toLowerCase().includes(value.toLowerCase())
			);
			setFilteredOptions(optionsList);
			setSearchTerm(value);
		},
		[options]
	);

	const customStyle: StylesConfig = {
		control: (styles: { [key: string]: any }) => ({
			...styles,
			backgroundColor: isError ? '#f5626217' : '#f5f8ff',
			minHeight: 52,
			border: isError
				? '1px solid #cc4e4e'
				: '1px solid rgba(215, 223, 245, 1)',
			borderRadius: 8,
			fontWeight: '600',
			color: '#8C97B8',
			boxShadow: 'none',
			fontSize: 14,
			'&:hover': {
				cursor: 'pointer',
				borderColor: '#ced2de',
			},
			'&:focus': {
				borderColor: 'red',
			},
			...dropDownStyle,
		}),
		menu: (styles: { [key: string]: any }) => ({
			...styles,
			background: '#f5f8ff',
			borderRadius: 8,
			border: '1px solid #ced2de',
			maxHeight: optionsDropHeight ?? undefined,
		}),
		multiValue: (styles: { [key: string]: any }) => ({
			...styles,
			backgroundColor: 'rgba(224, 233, 255, 1)',
			color: 'rgba(47, 50, 61, 1)',
			borderRadius: 50,
			paddingLeft: 8,
			paddingRight: 8,
			paddingTop: 4,
			paddingBottom: 4,
			margin: 4,
		}),

		multiValueRemove: (styles: any) => ({
			...styles,
			color: 'rgba(163, 177, 214, 1)',
			borderRadius: 50,
			marginLeft: 2,
			fontSize: 12,
		}),
		option: (styles: { [key: string]: any }) => {
			return {
				...styles,
				backgroundColor: '#f5f8ff',
				color: '#131314',
				height: 56,
				cursor: 'pointer',
				paddingTop: 12,
				paddingBottom: 12,
				borderBottom: '1px solid #ced2de',
				fontWeight: '500',
				fontSize: 16,
				...optionDropDownStyle?.baseStyle,
				'&:hover': {
					color: '#fff',
					backgroundColor: 'var(--color-primary-light)',
					...optionDropDownStyle?.hoverStyle,
				},

				'&:last-child': {
					borderBottom: 'none',
				},
			};
		},
	};

	const getPlaceholder = useMemo(() => {
		return isEmptyArrayObject(value) ? (
			<div className="Select-placeholder">{placeholder}</div>
		) : (
			value
		);
	}, [placeholder, value]) as ReactNode;

	const scrollProps = {
		...(maxMenuHeight && {
			maxMenuHeight: maxMenuHeight,
			menuPlacement: 'auto' as IMenuType,
		}),
	};

	const handleDismiss = () => {
		setPopupVisible(false);
		setSearchTerm('');
	};

	const handleMobileChange = (newValue: IOption) => {
		setTempValue(newValue);
		if (handleChangeSelect) handleChangeSelect(newValue);
		handleDismiss();
	};

	// const handleOk = () => {
	// 	if (filteredOptions.length === 1) {
	// 		const first = filteredOptions[0];
	// 		if (first) {
	// 			handleChangeSelect?.(first);
	// 			setPopupVisible(false);
	// 			return;
	// 		}
	// 	}
	// 	const selectedOption = options.find(
	// 		option => option.value === tempValue?.value
	// 	);
	// 	if (selectedOption && handleChangeSelect) {
	// 		handleChangeSelect(selectedOption);
	// 	}
	// 	setPopupVisible(false);
	// 	setSearchTerm('');
	// };

	const dropdownOptions = useMemo(
		() => (searchTerm ? filteredOptions : options),
		[filteredOptions, options, searchTerm]
	);

	if (isMobileDevice()) {
		return (
			<div id="dropdown__container" style={{ width: containerWidth }}>
				{label && (
					<label htmlFor={label} className="input__label">
						{label}
						{isRequired && <span className="input__isRequired"> *</span>}
					</label>
				)}
				<Popup
					visible={popupVisible}
					onDismiss={handleDismiss}
					// onOk={handleOk}
					okText=""
					content={
						<Fragment>
							<Input
								inputType="text"
								placeholder={'Search..'}
								handleChange={e => handleSearch(e.target.value)}
								label=""
								className="input-mobile-search"
								value={searchTerm}
							/>
							<div className="input-mobile--body">
								{dropdownOptions?.length ? (
									dropdownOptions.map(option => (
										<button
											key={v4()}
											className="input-mobile--list"
											onClick={() => handleMobileChange(option)}
											style={{
												fontWeight:
													option.value === tempValue?.value ? 600 : 400,
											}}
										>
											{option.label}
										</button>
									))
								) : (
									<RMCPicker.Item value={null}>
										No data available
									</RMCPicker.Item>
								)}
							</div>
						</Fragment>
					}
				>
					<div
						onClick={() => {
							setPopupVisible(true);
						}}
					>
						<Input
							readOnly
							inputType="text"
							placeholder={'Select...'}
							handleChange={() => {}}
							value={
								value?.label ?? tempValue?.label ?? defaultValue?.label ?? ''
							}
							label={''}
							isError={isError}
							errorMessage={errorMessage}
						/>
					</div>
				</Popup>
			</div>
		);
	}

	const customComponent: any = {
		IndicatorSeparator: () => null,
	};

	if (getCustomOptions) {
		customComponent['Option'] = getCustomOptions;
	}

	return (
		<div id="dropdown__container" style={{ width: containerWidth }}>
			{label && (
				<label htmlFor={label} className="input__label">
					{label}
					{isRequired && <span className="input__isRequired"> *</span>}
				</label>
			)}
			<Select
				defaultValue={defaultValue}
				isMulti={isMulti}
				value={value}
				className="dropdown"
				styles={customStyle}
				options={options}
				isClearable={false}
				closeMenuOnSelect={!isMulti}
				isSearchable={isSearchable}
				placeholder={getPlaceholder}
				isDisabled={isDisabled}
				{...scrollProps}
				onChange={handleChangeSelect as any}
				menuPlacement={menuPlacement}
				components={customComponent}
			/>
			{isError && (
				<div className="dropdown__error--message">{errorMessage}</div>
			)}
		</div>
	);
};
