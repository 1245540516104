import { BodyNameKeyType } from 'states';

export const ADDRESS_DETAILS_FORM = [
	{
		name: 'streetAddress',
		label: 'Street Address',
		placeholder: 'Street Address',
		type: 'text',
		isRequired: true,
	},
	{
		name: 'building',
		label: 'Building, Floor, etc.',
		placeholder: 'Building, Floor, etc.',
		type: 'text',
		isRequired: false,
	},
	{
		name: 'zipCode',
		label: 'Zip Code',
		placeholder: 'Zip Code',
		type: 'text',
		isRequired: true,
	},
	{
		name: 'country',
		label: 'Country',
		placeholder: 'Select Country',
		type: 'dropdown',
		isRequired: true,
	},
	{
		name: 'state',
		label: 'State',
		placeholder: 'State',
		type: 'stateSelect',
		isRequired: true,
	},
	{
		name: 'city',
		label: 'City',
		placeholder: 'City',
		type: 'text',
		isRequired: true,
	},
];

export const Select_Provider = {
	title: 'Choose Verification Provider',
	subTitle:
		'Please select verification provider you would like to submit for kyc approval.',
	provider: [
		{
			key: 'lexis-nexis',
			label: 'Lexis-nexis',
			image: 'ri-building-line',
		},
		{
			key: 'berbix',
			label: 'Berbix',
			image: 'ri-building-line',
		},
	],
};

export const Confirm_Details = {
	title: 'Confirm Your Details',
	subTitle:
		'Here’s what we’ve found on your identification document. Please edit if necessary and submit.',
	detailInputs: [
		{
			key: 'firstName',
			type: 'text',
			label: 'Given Name',
		},
		{
			key: 'middleName',
			type: 'text',
			label: 'Middle Name',
		},
		{
			key: 'lastName',
			type: 'text',
			label: 'Family/Last Name',
		},
		{
			key: 'dateOfBirth',
			type: 'date',
			label: 'Date of Birth',
		},
		{
			key: 'dateOfExpiry',
			type: 'date',
			label: 'Expiry Date',
		},
	],
};

export const personalDetailsError = 'Something went wrong';

export const personalDetailsSuccess = 'Submitted Successfully';

export const CardFrontOutline_URL =
	'https://storage.googleapis.com/satschel-assets-public/assets/illustrations/simplici-media/CardFrontOutline.png';
export const CardBackOutline_URL =
	'https://storage.googleapis.com/satschel-assets-public/assets/illustrations/simplici-media/CardBackOutline.png';

export const documentTypeMapping: any = {
	driverLicenseVerificationSteps: 'DL',
	passportVerificationSteps: 'passport',
	nationalIdCardVerificationSteps: 'nationalID',
};

export const KYC_DOC_UPLOAD_PROPS = {
	step2: {
		keyName: 'frontImage',
		btnNamefirst: 'Use My Camera',
		btnNameSecond: 'Choose Photo',
		canvasImage: CardFrontOutline_URL,
	},
	step3: {
		keyName: 'backImage',
		btnNamefirst: 'Use My Camera',
		btnNameSecond: 'Choose Photo',
		canvasImage: CardBackOutline_URL,
	},
	getBlankImage: (docType: string, step: string, isKnowDoc: boolean) =>
		step === 'step2'
			? docType == 'passport' || !isKnowDoc
				? 'blank-id-card-updated.svg'
				: 'FrontBlankDL.svg'
			: 'driving_license_back_img.svg',
};

export const IndentityVerificationObject = [
	{
		_id: 1,
		name: 'Driver’s License',
		leftIcon: 'car.svg',
		rightIcon: 'next.svg',
		key: 'driverLicenseVerificationSteps' as BodyNameKeyType,
		noOfSides: '2',
	},
	{
		_id: 1,
		name: 'Passport',
		leftIcon: 'globe.svg',
		rightIcon: 'next.svg',
		key: 'passportVerificationSteps' as BodyNameKeyType,
		noOfSides: '1',
	},
];

export const pipelineMobileScreenBodySettings: any = {
	driverLicenseVerificationSteps: {
		step1: {
			label: ' Verification',
			title: 'Scan front and back of your ',
			subtitle:
				'Use a well lit area and make sure the details are clear and unobstructed.',

			settings: [
				{
					step: 'step2',
					label: 'Start Verification',
					className:
						'button__filled button__filled--primary button__large button__block',
					isDisabled: false,
				},
				{
					step: 'step0',
					label: 'Back',
					className:
						'button__filled button__filled--secondary button__large button__block',
					isDisabled: false,
				},
			],
		},
		step2: {
			label: ' Verification',
			title: 'Please provide a clear photo of the front of your ',
			errorMessage: 'Front image is not clear, please retake the image',
			settings: [
				{
					step: 'step3',
					label: 'Next',
					className:
						'button__filled button__filled--primary button__large button__block',
					isDisabled: true,
				},
				{
					step: 'step0',
					label: 'Back',
					className:
						'button__filled button__filled--secondary button__large button__block',
					isDisabled: false,
				},
			],
		},
		step3: {
			label: ' Verification',
			title: 'Please provide a clear photo of the back of your ',
			errorMessage: 'Back image is not clear, please retake the image',
			subtitle: 'Please make sure the details are clear and unobstructed.',

			settings: [
				{
					step: 'step4',
					label: 'Next',
					className:
						'button__filled button__filled--primary button__large button__block',
					isDisabled: true,
				},
				{
					step: 'step2',
					label: 'Back',
					className:
						'button__filled button__filled--secondary button__large button__block',
					isDisabled: false,
				},
			],
		},
		step4: {
			label: 'Selfie Match',
			title: 'Take a Selfie',
			subtitle:
				' The image should be clear and have your face fully inside the frame' +
				' Make sure your eyes are clearly visible and remove your glasses if necessary.',

			settings: [
				{
					step: 'Submit',
					label: 'Submit',
					className:
						'button__filled button__filled--primary button__large button__block',
					isDisabled: true,
				},
				{
					step: 'step3',
					label: 'Back',
					className:
						'button__filled button__filled--secondary button__large button__block',
					isDisabled: false,
				},
			],
		},
		blankImage: 'dl-blank-card.svg', //always keep blankImage in last
	},
	passportVerificationSteps: {
		step1: {
			label: ' Verification',
			title: 'Scan front of your ',
			subtitle:
				'Please provide a clear, high-quality scan of the front page of your document as part of the KYC (Know Your Customer) verification process.',
			settings: [
				{
					step: 'step2',
					label: 'Start Verification',
					className:
						'button__filled button__filled--primary button__large button__block',
					isDisabled: false,
				},
				{
					step: 'step0',
					label: 'Back',
					className:
						'button__filled button__filled--secondary button__large button__block',
					isDisabled: false,
				},
			],
		},
		step2: {
			label: ' Verification',
			title: 'Scan front of your ',
			subtitle:
				'Please provide a clear, high-quality scan of the front page of your document as part of the KYC (Know Your Customer) verification process.',
			settings: [
				{
					step: 'step4',
					label: 'Next',
					className:
						'button__filled button__filled--primary button__large button__block',
					isDisabled: true,
				},
				{
					step: 'step0',
					label: 'Back',
					className:
						'button__filled button__filled--secondary button__large button__block',
					isDisabled: false,
				},
			],
		},
		// step3: {
		//   label: "Passport Verification",
		//   title: "Scan data page of your passport",
		//   subtitle:
		//     "Use a well lit area and make sure the details are clear and unobstructed.",
		//   settings: [
		//     {
		//       step: "step4",
		//       label: "Next",
		//       className:
		//         "button__filled button__filled--primary button__large button__block",
		//       isDisabled: true,
		//     },
		//     {
		//       step: "step2",
		//       label: "Back",
		//       className:
		//         "button__filled button__filled--secondary button__large button__block",
		//       isDisabled: false,
		//     },
		//   ],
		// },
		step4: {
			label: 'Selfie Match',
			title: 'Take a Selfie',
			subtitle:
				' The image should be clear and have your face fully inside the frame' +
				' Make sure your eyes are clearly visible and remove your glasses if necessary.',

			settings: [
				{
					step: 'Submit',
					label: 'Submit',
					className:
						'button__filled button__filled--primary button__large button__block',
					isDisabled: true,
				},
				{
					step: 'step3',
					label: 'Back',
					className:
						'button__filled button__filled--secondary button__large button__block',
					isDisabled: false,
				},
			],
		},
		blankImage: 'passport-blank-card.svg', //always keep blankImage in last
	},
	nationalIdCardVerificationSteps: {
		step1: {
			label: 'National Photo ID Verification',
			title: 'Scan front and back of your National photo ID ',
			subtitle:
				'Use a well lit area and make sure the details are clear and unobstructed.',
			settings: [
				{
					step: 'step2',
					label: 'Start Verification',
					className:
						'button__filled button__filled--primary button__large button__block',
					isDisabled: false,
				},
				{
					step: 'step0',
					label: 'Back',
					className:
						'button__filled button__filled--secondary button__large button__block',
					isDisabled: false,
				},
			],
		},
		step2: {
			label: 'National Photo ID Verification',
			title: 'Scan front of your national photo ID ',
			subtitle:
				'Use a well lit area and make sure the details are clear and unobstructed.',
			settings: [
				{
					step: 'step3',
					label: 'Next',
					className:
						'button__filled button__filled--primary button__large button__block',
					isDisabled: true,
				},
				{
					step: 'step0',
					label: 'Back',
					className:
						'button__filled button__filled--secondary button__large button__block',
					isDisabled: false,
				},
			],
		},
		step3: {
			label: 'National Photo ID Verification',
			title: 'Scan Back of your national photo ID ',
			subtitle:
				'Use a well lit area and make sure the details are clear and unobstructed.',
			settings: [
				{
					step: 'step4',
					label: 'Next',
					className:
						'button__filled button__filled--primary button__large button__block',
					isDisabled: true,
				},
				{
					step: 'step2',
					label: 'Back',
					className:
						'button__filled button__filled--secondary button__large button__block',
					isDisabled: false,
				},
			],
		},
		step4: {
			label: 'Selfie Match',
			title: 'Take a Selfie',
			subtitle:
				' The image should be clear and have your face fully inside the frame' +
				' Make sure your eyes are clearly visible and remove your glasses if necessary.',

			settings: [
				{
					step: 'Submit',
					label: 'Submit',
					className:
						'button__filled button__filled--primary button__large button__block',
					isDisabled: true,
				},
				{
					step: 'step3',
					label: 'Back',
					className:
						'button__filled button__filled--secondary button__large button__block',
					isDisabled: false,
				},
			],
		},
		blankImage: 'national-id-blank-card.svg', //always keep blankImage in last
	},
};

export const accrediationObject = [
	{
		title: 'No password',
		description: 'We do not store or share any passwords or login credential',
		icon: 'ri-lock-2-fill',
	},
	{
		title: 'Strong Privacy',
		description:
			'We do not share any data with third parties without your consents',
		icon: 'ri-eye-off-fill',
	},
	{
		title: 'Secure',
		description:
			'Your data stays fully encrypted using highest industry standard',
		icon: 'ri-shield-check-fill',
	},
];

export const instructionUploadImg = {
	TIPS: 'Tips for a better photo:',
	step1: 'Place the document in a well-lit area.',
	step2: 'Avoid direct light sources that may cause glare.',
	step3: 'Ensure the document is flat and not tilted.',
};

export const mobileInstructionUploadImg = {
	step1: 'Use a well-lit area.',
	step2: 'Avoid direct glare.',
	step3: 'Keep the document flat.',
};

export const fundInvestmentVerification = {
	label: 'Fund Investment',
	key: 'fundInvestment',
	description: 'This action allows user to connect bank account.',
	actions: {
		label: 'Fund Investment',
		description: 'Fund investment description',
		key: 'fundInvestmentVerification',
		price: 0.1,
		metadata: [
			{
				type: 'select',
				label: 'Select Country',
				key: 'fundInvestmentCountry',
				description: '',
				default: '',
				placeholder: 'Country',
			},
			{
				type: 'select',
				label: 'Language',
				key: 'fundInvestmentLanguage',
				description: '',
				default: '',
				placeholder: 'Language',
			},
			{
				type: 'button',
				label: 'Next',
				key: 'fundInvestmentSubmit',
				description: '',
				default: '',
				placeholder: 'Language',
			},
		],
	},
};
export const multiSelectValue = [''];

export const fundInvestmentDropdownObject = {
	fundInvestmentCountry: [
		{ value: 'ES', label: 'Spain' },
		{ value: 'NL', label: 'Netherlands' },
		{ value: 'US', label: 'United States' },
		{ value: 'GB ', label: 'United Kingdom' },
		{ value: 'CA', label: 'Canada' },
	],
	fundInvestmentLanguage: [
		{ value: 'en', label: 'English' },
		{ value: 'fr', label: 'French' },
		{ value: 'es', label: 'Spanish' },
		{ value: 'de', label: 'German' },
	],
};
export const dropDownDefaultValue = {
	fundInvestmentCountry: {
		label: 'United States',
		value: 'US',
	},
	fundInvestmentLanguage: {
		label: 'English',
		value: 'en',
	},
	fundInvestmentbank: {
		label: '1',
		value: '1',
	},
};

export const finraLisenceDropValue = [
	{
		value: 'Licensed General Securities Representative (Series 7)',
		label: 'Licensed General Securities Representative (Series 7)',
	},
	{
		value: 'Licensed Investment Adviser Representative (Series 65)',
		label: 'Licensed Investment Adviser Representative (Series 65)',
	},
	{
		value: 'Licensed Private Securities Offerings Representative (Series 82)',
		label: 'Licensed Private Securities Offerings Representative (Series 82)',
	},
];
export const finraLisenceDropDownDefaultValue = {
	value: 'Licensed General Securities Representative (Series 7)',
	label: 'Licensed General Securities Representative (Series 7)',
};

export const uploadPanelKeys = [
	'networthUploadDocuments',
	'incomeUploadDocuments',
	'noneOfAboveUploadDocuments',
];

export const forceOpen = ['accreditationVerify'];

export const QuestionnireState = ['formAction', 'dynamicForm', 'form'];

export const signAgreementState = [
	'accreditationVerify',
	'signAgreementVerification',
];

export const accredListsKeys = [
	'individualAccreditationVerify',
	'incomeValidation',
	'netWorthValidation',
	'netWorthValidation',
];
export const radioCardSelectionList = [
	'individualAccreditationVerify',
	'incomeValidation',
	'netWorthValidation',
	'entityAccreditationVerify',
	'describeEntity',
	'corporationPartnership',
	'underSignedTrust',
	'selfDirectedEmployee',
	'privateBusinessDevelopment',
	'ownerOfCompany',
	'describeEntityVerify',
	'describeEntityEquityOwnerVerify',
];

export const getUploadDocumentList = [
	'incomeUploadDocuments',
	'noneOfAboveUploadDocuments',
	'networthUploadDocuments',
	'entityCommonUpload',
	'underSignedTrustUpload',
	'selfDirectedEmployeeUpload',
	'privateBusinessDevelopmentUpload',
	'corporationPartnershipUpload',
	'commonUploadDocuments',
];

export const entityAccrediationFormProperties = [
	{
		label: 'Name of the Entity',
		type: 'input',
		name: 'entityName',
		placeHolder: 'Entity Name',
	},
	{
		label: 'Name ',
		type: 'input',
		name: 'name',
		placeHolder: 'Enter Your Name',
	},
	{
		label: 'Title/Designation',
		type: 'input',
		name: 'designation',
		placeHolder: 'Enter Your Title or Designation',
	},
	{
		label: 'Next',
		type: 'button',
		name: '',
		placeHolder: '',
	},
	{
		label: 'Back',
		type: 'button',
		name: '',
		placeHolder: '',
	},
];

export const describeEntityFormProperties = [
	{
		label: null,
		type: 'textarea',
		name: 'describe',
		placeHolder: 'Describe Entity here',
		defaultRows: 5,
	},
	{
		label: 'Next',
		type: 'button',
		name: '',
		placeHolder: '',
	},
	{
		label: 'Back',
		type: 'button',
		name: '',
		placeHolder: '',
	},
];

export const forceCallAPIForIFrame = [
	'corporationPartnership',
	'privateBusinessDevelopment',
	'underSignedTrust',
	'selfDirectedEmployee',
	'netWorthValidation',
	'incomeValidation',
	'finraUploadDocuments',
	'ownerOfCompany',
	'noneOfAboveUploadDocuments',
	'entityCommonUpload',
];

export const vouchedCameraResult = {
	title: 'Well done! here’s the captured photo',
	subtitle:
		'Please take a moment to review it. If it looks good, you can submit it. Otherwise, feel free to retake the picture.',
};

export const kycDocSvgIcons = {
	Default: 'kyc-doc-default.svg',
	'Driving Licence': 'car.svg',
	Passport: 'globe.svg',
	'Residence Permit': 'kyc-doc-resident-permit.svg',
	'Asylum Registration Card': 'kyc-doc-asylum-registration.svg',
	Visa: 'kyc-doc-visa.svg',
	'National Health Insurance Card': 'kyc-doc-national-health-insurance.svg',
	'Proof of Citizenship': 'kyc-doc-proof-of-citizenship.svg',
	'National Identity Card': 'kyc-doc-national-id.svg',
	'Private Operators Card': 'kyc-doc-private-operators.svg',
	'Vehicle Registration Card': 'kyc-doc-vehicle-registration.svg',
	'Postal Identity Card': 'kyc-doc-postal-id.svg',
	'Social Security Card': 'kyc-doc-social-security.svg',
	'Voter Id': 'kyc-doc-voter-id.svg',
	'Tax Id': 'kyc-doc-tax-id.svg',
	'Work Permit': 'kyc-doc-work-permit.svg',
	'Professional Qualification Card': 'kyc-doc-professional-qualification.svg',
	'Consular ID': 'kyc-doc-consular-id.svg',
	'Residence Status Document': 'kyc-doc-residence-status.svg',
	'Immigration Status Document': 'kyc-doc-immigration-status.svg',
	'Service ID Card': 'kyc-doc-service-id.svg',
	'Indigenous Card': 'kyc-doc-indigenous.svg',
	'Passport Card': 'globe.svg',
	'International Driving Licence': 'kyc-doc-international-driving-licence.svg',
	'Municipality Identity Card': 'kyc-doc-municipality-identity.svg',
	'Certificate of Naturalisation': 'kyc-doc-certificate-of-naturalisation.svg',
};
