import { useCallback, useMemo } from 'react';
import { v4 } from 'uuid';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { PaymentMethod, PaymentSelectorProps } from './types';
import { SessionDetailsState } from 'hooks/use-next-step/stores';
import {
	FINIX_BUISNESS,
	FINIX_PAYMENT_METHOD,
	PAYMENT_OPTIONS,
} from './constant';
import { SelectedPaymentMethodState } from 'views/fund-investment/stores';
import useApplePay from './apple-pay/use-apple-pay';

import './payment-select.scss';
import { useNextStep, useNotification } from 'hooks';

export const PaymentSelector = ({
	onMethodSelect,
	handleBacktoAmoutScreen,
	error,
}: PaymentSelectorProps) => {
	const setSelectedPaymentMethod = useSetRecoilState(
		SelectedPaymentMethodState
	);
	const { errorNotification } = useNotification();
	const { sessionPayloadDetail } = useNextStep();
	const { investingAmount } = useMemo(
		() => sessionPayloadDetail ?? {},
		[sessionPayloadDetail]
	);
	const isAndroid = useMemo(() => /Android/i.test(navigator.userAgent), []);
	const { handleApplePayClick } = useApplePay();

	const handleSelect = useCallback(
		(method: PaymentMethod) => {
			if (method === 'apple-pay') {
				if (investingAmount > 10000) {
					errorNotification(
						'Transaction limit exceeded: Apple Pay transactions are limited to $10,000.'
					);
					handleBacktoAmoutScreen();
					return;
				}
				handleApplePayClick();
				return;
			}
			setSelectedPaymentMethod(method);
			onMethodSelect(method);
		},
		[
			errorNotification,
			handleApplePayClick,
			handleBacktoAmoutScreen,
			investingAmount,
			onMethodSelect,
			setSelectedPaymentMethod,
		]
	);

	const sessionDetails = useRecoilValue(SessionDetailsState);
	const { businessId = '' } = sessionDetails ?? {};

	return (
		<div className="payment-selector">
			<div className="payment-selector__option--wrapper">
				{(FINIX_BUISNESS.includes(businessId ?? '')
					? FINIX_PAYMENT_METHOD
					: PAYMENT_OPTIONS
				).map(option =>
					isAndroid && option.id === 'apple-pay' ? (
						<></>
					) : (
						<div
							key={v4()}
							onClick={() => handleSelect(option.id)}
							className="payment-selector__card"
						>
							<div className="payment-selector__icon">
								<i className={option.icon}></i>
							</div>
							<div className="payment-selector__right--section">
								<div className="payment-selector__card-heading--wrapper">
									<div className="payment-selector__card--heading">
										{option.label}
									</div>
									<div className="payment-selector__card--headarrow">
										<i className="ri-arrow-right-line"></i>
									</div>
								</div>
								<div className="payment-selector__label">
									{option.description}
								</div>
							</div>
						</div>
					)
				)}
			</div>
			{error && (
				<div className="payment-selector__error" role="alert">
					{error}
				</div>
			)}
		</div>
	);
};
