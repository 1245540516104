import env from '@beam-australia/react-env';

export const REACT_APP_API_HOST = (window as any).API_HOST
	? (window as any).API_HOST
	: env('API_HOST');
export const REACT_APP_OCR_HOST = 'https://api-ocr.satschel.com/v1';

export const REACT_APP_CDN_URL = env('CDN_URL');
export const REACT_APP_GLARE_API = env('GLARE_API');
export const REACT_APP_LIQUIDITY_BUSINESSID = env('LIQUIDITY_BUSINESSID');
export const REACT_APP_SEGMENT_KEY = env('SEGMENT_KEY');
export const REACT_APP_GOOGLE_MAP_SECRET_TOKEN = env('GOOGLE_MAP_SECRET_TOKEN');
export const REACT_APP_ENROLL_WEBRTC_URL = env('ENROLL_WEBRTC_URL');
export const REACT_APP_ENROLL_CUSTOM_SERVERS = env('ENROLL_CUSTOM_SERVERS');
export const REACT_APP_ENROLL_CUSTOM_SERVERS_API_URL = env(
	'ENROLL_CUSTOM_SERVERS_API_URL'
);
export const REACT_APP_ENROLL_CUSTOM_SERVERS_BEARER_TOKEN = env(
	'ENROLL_CUSTOM_SERVERS_BEARER_TOKEN'
);
export const REACT_APP_NON_OF_ABOVE_HIDE = env('NON_OF_ABOVE_HIDE');
export const REACT_APP_WEB_SOCKET = env('WEB_SOCKET');
export const REACT_APP_LIQUIDITY_DOMAIN = env('LIQUIDITY_DOMAIN');
export const REACT_APP_KYC_DOMAIN =
	env('KYC_DOMAIN') || 'https://secure.stage.satschel.com';
export const REACT_APP_SKIP_PHONE = env('SKIP_PHONE');
export const REACT_APP_FINIX_ENV_TYPE = env('FINIX_ENV_TYPE');
