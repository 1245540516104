import { useCallback, useMemo } from 'react';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';

import { APIS } from 'constants/api';
import { MAX_RETRIES, RETRY_DELAY } from 'constants/common';
import { useSharedVariables } from 'hooks/shared-variable';
import { AccessTokenState, QrStatusCodeState } from 'states';
import { retryOperation } from 'utils/retry-api-call';
import { IAccessToken } from 'states/token/type';

interface IUpdateAccessToken {
	[key: 'qrId' | string]: keyof IAccessToken;
}

export const useRegenerate = () => {
	// Hook to set the `AccessTokenState` atom
	const setAccessToken = useSetRecoilState(AccessTokenState);

	// Recoil state for `QrStatusCodeState` with setter and getter
	const [qrStatusCode, setQrStatusCode] = useRecoilState(QrStatusCodeState);

	// Hook to reset the `AccessTokenState` atom to its default value
	const resetAccessToken = useResetRecoilState(AccessTokenState);

	// Extract shared variables like `sessionId` and `onboardingType` from a custom hook
	const { sessionId, onboardingType } = useSharedVariables();

	// Memoized value to determine the token type based on onboarding type
	const getTokenType = useMemo(
		() => (onboardingType === 'qr' ? 'qr' : 'session'),
		[onboardingType]
	);

	// Helper function to update the access token in the Recoil state
	const updateAccessToken = useCallback(
		(data: IUpdateAccessToken) => {
			setAccessToken({
				token: data?.token ?? '', // Set the token value or default to an empty string
				code: data?.code ?? data?.qrId ?? '', // Set the code or fall back to `qrId` or an empty string
			});
		},
		[setAccessToken]
	);

	// Helper function to update the QR status code after a delay
	const updateQrStatusCode = useCallback(
		(statusCode: number) => {
			if (qrStatusCode !== 400 && qrStatusCode !== 500) {
				// Set the QR status code after a 2-second delay if it's not a server error
				setTimeout(() => setQrStatusCode(statusCode), 2000);
			}
		},
		[qrStatusCode, setQrStatusCode]
	);

	// Main function to regenerate the token, retries on failure, and updates state accordingly
	const regenerateToken = useCallback(
		async (get: (url: string) => Promise<any>) => {
			// Reset the access token before attempting to regenerate it
			resetAccessToken();

			// Construct the API endpoint with the session ID and token type
			const url = `${APIS.TOKEN}/${sessionId}?type=${getTokenType}`;

			try {
				// Retry the API call based on configured retry logic
				const response = await retryOperation(
					() => get(url),
					MAX_RETRIES,
					RETRY_DELAY
				);

				const { data, statusCode } = response ?? {};

				if (data) {
					// Update access token and QR status code if data is received
					updateAccessToken(data);
					updateQrStatusCode(statusCode);
					return data; // Return the token data
				} else {
					// Handle case where no data is returned
					setQrStatusCode(statusCode); // Unauthorized
					resetAccessToken();
					return null; // Return null to indicate failure
				}
			} catch (error) {
				// Handle errors by resetting state and setting an unauthorized status code
				setQrStatusCode(401);
				resetAccessToken();
				return null;
			}
		},
		[
			resetAccessToken,
			sessionId,
			getTokenType,
			updateAccessToken,
			updateQrStatusCode,
			setQrStatusCode,
		]
	);

	// Return the `regenerateToken` function for external use
	return { regenerateToken };
};
