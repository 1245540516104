import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import './error-page.scss';
import { Button, Image } from '@storybook';

type StatusType = 400 | 404 | 500 | 'default';

interface IErrorPage {
	status?: StatusType;
	imageUrl?: string;
	heading?: string;
	description?: string | JSX.Element;
	actionContent?: JSX.Element;
}
export const ErrorPage: FC<IErrorPage> = ({
	status = 'default',
	imageUrl,
	heading,
	description,
	actionContent,
}) => {
	const [code, setCode] = useState(status);
	const param = useParams();

	useEffect(() => {
		if (typeof param?.errors === 'string') {
			const parsedError = parseInt(param.errors, 10);
			if ([400, 404, 500].includes(parsedError)) {
				setCode(parsedError as StatusType);
			}
		}
	}, [param.errors]);

	const getImage = {
		400: '400.svg',
		404: '404.svg',
		500: '500.svg',
		default: 'deafult_error.svg',
	};

	const getDescription = {
		400: 'Your Request resulted in an Error',
		404: 'The Page You are looking for can not be found',
		500: 'Please try again or contact support ',
		default:
			'We apologize for any inconvenience this may cause and appreciate your patience. Our team is already on it and working to resolve the problem.',
	};

	const getHeading = {
		400: 'Bad Request!',
		404: 'Whoops!',
		500: 'Server Error!',
		default: "We're sorry, but it seems like something went wrong",
	};

	const imageProps = {
		...(imageUrl
			? { url: imageUrl }
			: { fileName: getImage[code] ?? getImage['default'] }),
		className: 'error-wrapper__image',
	};
	return (
		<div className="error-wrapper">
			<Image {...imageProps} />
			<div className="error-wrapper__text-container">
				<div className="error-wrapper__heading">
					{heading ?? getHeading[code] ?? getHeading['default']}{' '}
				</div>
				<div className="error-wrapper__desc">
					{description ?? getDescription[code] ?? getDescription['default']}
				</div>
				{actionContent}
				{!getDescription[code] && !description && (
					<Button
						label={'Try Again'}
						handleClick={() => location.reload()}
						type="button__filled--primary default__error-btn"
					/>
				)}
			</div>
		</div>
	);
};
