import { API_TYPE, ITokenApiType } from 'constants/api';
import { APIS } from 'constants/api';
import { useNetwork } from 'hooks/network';
import { useSharedVariables } from 'hooks/shared-variable';
import { useNextStep } from 'hooks/use-next-step';
import { IsInvestingAmountEmptyState } from 'views/fund-investment/stores';
import { retryOperation } from 'utils/retry-api-call';
import { QrStatusCodeState } from 'states';
import { useSetRecoilState } from 'recoil';
import { MAX_RETRIES, RETRY_DELAY } from 'constants/common';
import { IsShowOwnerShipOptionKybMemberListState } from 'views/kyb/stores';
import { PSBBusinessId } from 'views/kyb/components/representative-detail/constant';

interface IpostTokenSession {
	payload?: any;
	type?: ITokenApiType;
	code?: string;
	apiHost?: string;
	customConfig?: any;
	nodeId?: any;
	url?: string;
}

// Define the custom hook useTokenSession
export const useTokenSession = () => {
	const setIsInvestingAmountEmpty = useSetRecoilState(
		IsInvestingAmountEmptyState
	);

	// Destructure the get and post functions from the useNetwork hook
	const { get, post, loading } = useNetwork();
	const { setSessionDetails } = useNextStep();

	// Destructure sessionId from useSharedVariables hook
	const { sessionId } = useSharedVariables();

	// Set the default API type as UPDATE
	const defaultType = API_TYPE.UPDATE;

	// Define the token URL
	const tokenUrl = APIS.TOKEN;

	const setQrStatusCode = useSetRecoilState(QrStatusCodeState);
	const setIsPSBBusinessId = useSetRecoilState(
		IsShowOwnerShipOptionKybMemberListState
	);
	// Define a function to get token session asynchronously
	const getTokenSession = async (props: IpostTokenSession): Promise<any> => {
		const { url, apiHost, customConfig } = props ?? {};
		try {
			// Make a GET request to the token URL
			const resp = await retryOperation(
				() => get(url ?? tokenUrl, apiHost, customConfig),
				MAX_RETRIES, // Max retries count
				RETRY_DELAY
			);
			// Return the response
			return resp;
		} catch (error) {
			// Return the error if there is any
			return error;
		}
	};

	// Define a function to post asynchronously
	const postSession = async (props: IpostTokenSession): Promise<any> => {
		const { url = '', apiHost, customConfig, payload } = props ?? {};
		try {
			// Make a GET request to the token URL
			const resp = await retryOperation(
				() => post(url, payload, apiHost, customConfig),
				MAX_RETRIES, // Max retries count
				RETRY_DELAY
			);
			// Return the response
			return resp;
		} catch (error) {
			// Return the error if there is any
			return error;
		}
	};

	/**
	 * Hook postTokenSession
	 * @param payload - payload for the API that you want to request to the server
	 * @param type - API type, default is Update
	 */
	const postTokenSession = async (props: IpostTokenSession): Promise<any> => {
		const { url, payload, type, code, apiHost, customConfig, nodeId } = props;

		try {
			if (code || sessionId) {
				// Make a POST request to the token URL with the specified payload, session code, and type

				const response = await retryOperation(
					() =>
						post(
							url ?? tokenUrl,
							{
								code: code ?? sessionId,
								type: type ?? defaultType, // Use the provided type or default to defaultType
								payload,
								...(nodeId && { nodeId: nodeId }),
							},
							apiHost,
							customConfig
						),
					MAX_RETRIES, // Max retries count
					RETRY_DELAY
				);
				// Shahbaaz: Skip Accreditation 1st page if user coming from Individual or Entity Flow
				if (response?.actions?.[0]?.identityType) {
					setSessionDetails(prev => ({
						...prev,
						identityType: response?.actions?.[0]?.identityType ?? '',
					}));
				}

				const { statusCode, businessId } = response ?? {};
				if (!statusCode) setQrStatusCode(statusCode);

				if (businessId && PSBBusinessId.includes(businessId)) {
					setIsPSBBusinessId(true);
				}

				if (response?.fund?.name) {
					setSessionDetails(prev => ({
						...prev,
						fundName: response.fund.name ?? '',
						investingAmount: response.investingAmount,
						identityType: response?.actions?.[0]?.identityType ?? '',
					}));

					setIsInvestingAmountEmpty(
						!response.investingAmount || response.investingAmount === 0
					);
				}

				// Return the response
				return response as any;
			}
			return { message: 'Code not found!' };
		} catch (error) {
			// Return the error if there is any
			return error;
		}
	};

	// Return the getTokenSession and postTokenSession functions
	return {
		getTokenSession,
		postTokenSession,
		postSession,
		loading,
	};
};
