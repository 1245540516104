import { useCallback, useMemo } from 'react';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';

import { APIS } from 'constants/api';
import { useSharedVariables } from 'hooks/shared-variable';
import { AccessTokenState, CustomLoaderState, QrStatusCodeState } from 'states';
import { useNetwork } from '../network';
import { retryOperation } from 'utils/retry-api-call';
import { MAX_RETRIES, RETRY_DELAY } from 'constants/common';
import { checkHexaDecimal, getOrigin } from 'utils';

export const useToken = () => {
	const { apiEndPoint: API_HOST } = useSharedVariables();

	const [, setAccessToken] = useRecoilState(AccessTokenState);
	const [qrStatusCode, setQrStatusCodeState] =
		useRecoilState(QrStatusCodeState);
	const resetAccessToken = useResetRecoilState(AccessTokenState);
	const setCustomLoader = useSetRecoilState(CustomLoaderState);

	const { sessionId, onboardingType } = useSharedVariables();
	const { post, get } = useNetwork();

	const getTokenType = useMemo(
		() => (onboardingType === 'qr' ? onboardingType : 'session'),
		[onboardingType]
	);

	const getLoader = useCallback(
		async (code: string, accessToken: string) => {
			// eslint-disable-next-line no-console
			console.log(code);

			const url = `${APIS.TOKEN}?fetchLoader=true`;
			const type = checkHexaDecimal(code) ? 'qr' : 'code';
			const customConfig = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`,
					...(type === 'qr' && { Domain: getOrigin() }),
				},
			};
			const paylaod = {
				code,
				type,
			};
			const response = await retryOperation(
				() => post(url, paylaod, undefined, customConfig),
				MAX_RETRIES, // Max retries count
				RETRY_DELAY
			);
			const { loader, whitelabel, loaderWhitelabel } =
				response?.data ?? response ?? {};
			const customLoader = { loader, whitelabel, loaderWhitelabel };
			setCustomLoader({ ...customLoader });
			const json = JSON.stringify({ ...(customLoader ?? {}) });
			localStorage.setItem('session-loader', json);
		},
		[post, setCustomLoader]
	);

	const generateToken = useCallback(
		async (id?: string, type?: string) => {
			resetAccessToken();
			const url = `${APIS.TOKEN}/${id ?? sessionId}?type=${type ?? getTokenType}`;
			const response = await retryOperation(
				() => get(url),
				MAX_RETRIES, // Max retries count
				RETRY_DELAY
			);
			const { data, statusCode } = response ?? {};

			if (data) {
				if (data?.token) {
					getLoader(data?.code ?? data?.qrId, data.token);
				}
				setAccessToken({
					token: data?.token ?? '',
					code: data?.code ?? data?.qrId ?? '',
				});

				if (qrStatusCode !== 400 && qrStatusCode !== 500) {
					setTimeout(() => {
						setQrStatusCodeState(statusCode);
					}, 2000);
				}
				return data;
			} else {
				setQrStatusCodeState(statusCode);
				resetAccessToken();
				return null;
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[getTokenType, sessionId, qrStatusCode, API_HOST]
	);
	return { generateToken };
};
