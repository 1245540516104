import { useCallback, useMemo, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { APIS, API_TYPE } from 'constants/api';
import {
	useNextStep,
	useNotification,
	useSharedVariables,
	useTokenSession,
} from 'hooks';
import { FundBankDetailsState } from './states';
import { AccessTokenState, isShowSkipState } from 'states';
import { SESSION_TYPE, DEFAULT_AMOUNT_FOR_SELLER } from '../constants';
import { PAYMENT_FAILED } from 'constants/common';

export const useFundInvestmentRequests = () => {
	const setBankDetails = useSetRecoilState(FundBankDetailsState);
	const setIsShowSkip = useSetRecoilState(isShowSkipState);
	const { errorNotification, successNotification, warningNotification } =
		useNotification();
	const { sessionPayloadDetail, setSessionDetails } = useNextStep();
	const { code: sessionCode } = useRecoilValue(AccessTokenState);
	const { onboardingType } = useSharedVariables();
	const { postTokenSession } = useTokenSession();

	const [loadingWirePayment, setLoadingWirePayment] = useState(false);

	const {
		stepsId,
		_id: pipelineId,
		userId,
		currentAction,
		investingAmount,
		sessionType,
		sessionId,
	} = useMemo(() => sessionPayloadDetail ?? {}, [sessionPayloadDetail]);
	const isPayIn = useMemo(
		() => currentAction?.metadata?.payInPayOut === 'payIn',
		[currentAction?.metadata?.payInPayOut]
	);
	const isSeller = useMemo(
		() => sessionType === SESSION_TYPE.Seller,
		[sessionType]
	);

	const fetchBankDetails = async () => {
		setBankDetails({ isLoaded: false, data: null, error: false });
		const nodeIdParam =
			onboardingType === 'complex' ? `?nodeId=${currentAction._id}` : '';
		// PLAID_BALANCE get api
		const res = await postTokenSession({
			url: `${APIS.TOKEN}${nodeIdParam}`,
			code: sessionCode,
			type: API_TYPE.BALANCE,
		});
		if (res && res?.statusCode === 200) {
			setBankDetails({ isLoaded: true, data: res, error: false });
		} else {
			errorNotification(res?.message ?? 'Somthing Went Wrong');
			setBankDetails({
				isLoaded: true,
				data: res?.message ?? 'Somthing went wrong',
				error: true,
			});
		}
	};

	const { handleNext } = useNextStep();

	const finishPayment = useCallback(async () => {
		const payload = {
			nodeId: currentAction._id,
			actions: [{}],
		};
		// COMPLEX_SESSION patch api
		const res = await postTokenSession({ payload, code: sessionCode });
		if (res?.statusCode === 200) {
			const paymentResponse = { ...res };
			delete paymentResponse.statusCode;
			setSessionDetails(prev => ({
				...prev,
				nodes: paymentResponse,
			}));
		} else {
			errorNotification(res.message ?? 'Something Went Wrong');
		}
	}, [
		currentAction?._id,
		errorNotification,
		postTokenSession,
		sessionCode,
		setSessionDetails,
	]);

	const submitPayment = async (
		payload: any,
		onSuccess?: any,
		onFailed?: any
	) => {
		if (onboardingType === 'complex') {
			const complexPayload = {
				...payload,
				sessionId: pipelineId,
				nodeId: currentAction._id,
				amount:
					isPayIn && isSeller ? DEFAULT_AMOUNT_FOR_SELLER : investingAmount,
			};

			const finalPayload = {
				nodeId: currentAction._id,
				...complexPayload,
			};
			// COMPLEX_SESSION_PAYMENT post api
			const res = await postTokenSession({
				payload: finalPayload,
				type: API_TYPE.PAYMENT,
				nodeId: currentAction._id,
				code: sessionCode,
			});
			if (res?.statusCode === 200) {
				await finishPayment();
			} else if (/hold/.test(res?.message)) {
				await finishPayment();
				warningNotification(res?.message);
			} else onFailed(res);
			return;
		}
		// PLAID_PAY_AMOUNT post api
		const res = await postTokenSession({
			payload,
			type: API_TYPE.PAYMENT,
			code: sessionCode,
		});

		if (res?.statusCode === 200) onSuccess();
		else if (
			res?.message ===
				'Your investment is in hold until KYC or AML is being approved' ||
			res?.message === 'Your investment is in hold until KYC is being approved'
		) {
			handleNext();
			warningNotification(res?.message);
		} else onFailed(res);
	};

	const createToken = async (onSuccess?: any, onFailed?: any) => {
		const payload: any = {
			pipelineId: pipelineId ?? '',
			stepId: stepsId ?? '',
			userId: userId ?? '',
			actions: [
				{
					id: 'fundInvestmentVerification',
					data: {
						language: 'en',
						countryCodes: ['US'],
					},
				},
			],
		};
		// KYC_SESSION patch api
		const res = await postTokenSession({ payload, code: sessionCode });
		if (res?.statusCode === 200) onSuccess(res);
		else onFailed();
	};

	const linkAccount = async (payload: any) => {
		if (onboardingType === 'complex') {
			const { institutionName: bankName, ...rest } =
				payload.actions?.[0]?.data ?? {};
			const complexPayload = {
				bankName,
				sessionId: pipelineId,
				...rest,
			};
			// TOKEN_EXCHANGE  post api

			const res = await postTokenSession({
				payload: complexPayload,
				nodeId: currentAction._id,
				type: API_TYPE.TOKEN_EXCHANGE,
				code: sessionCode,
			});

			if (res?.statusCode === 200) {
				successNotification(res.message ?? 'Account Linked Successfully.');
			} else {
				errorNotification(res?.message ?? 'Something went wrong');
			}
			return;
		}
		// KYC_SESSION PATCH API
		const res = await postTokenSession({ payload, code: sessionCode });
		if (res?.statusCode === 200)
			successNotification(res.message ?? 'Account Linked Successfully.');
		else {
			setIsShowSkip(true);
			errorNotification(res.message ?? 'Something Went Wrong');
		}
	};

	const makeWirePayment = useCallback(async () => {
		// Prepare the payload with necessary details for the wire payment request
		const payload = {
			nodeId: currentAction._id, // Unique identifier for the current action node
			sessionId, // Session ID for tracking the transaction
			amount: investingAmount, // Amount the user intends to invest
			paymentMethod: 'WIRE', // Specifies the payment method as 'WIRE'
		};

		// Set the loading state to indicate the payment process has started
		setLoadingWirePayment(true);

		// Send the payment request and await the response
		const res = await postTokenSession({
			payload, // The payload containing payment details
			type: API_TYPE.PAYMENT, // API type indicating a payment request
			nodeId: currentAction._id, // Node ID for the transaction
			code: sessionCode, // Session code for additional security
		});

		// Check the response status to handle different outcomes
		if (res?.statusCode === 200) {
			// Payment succeeded, complete the process
			await finishPayment();
		} else if (/hold/.test(res?.message)) {
			// Payment is on hold, complete the process and show a warning notification
			await finishPayment();
			warningNotification(res?.message);
		} else {
			// Payment failed, show an error notification with the failure message
			errorNotification(res?.message ?? PAYMENT_FAILED);
		}

		// Reset the loading state after the payment process finishes
		setLoadingWirePayment(false);
		return;
	}, [
		currentAction._id,
		errorNotification,
		finishPayment,
		investingAmount,
		postTokenSession,
		sessionCode,
		sessionId,
		warningNotification,
	]);

	return {
		fetchBankDetails,
		submitPayment,
		createToken,
		linkAccount,
		makeWirePayment,
		loadingWirePayment,
	};
};
