import { useState, useCallback } from 'react';

interface UseScriptLoaderResult {
	isLoading?: boolean;
	error?: string | null;
	isScriptLoaded: boolean;
	retry?: () => void;
	reloadFinixScript?: () => void | undefined;
}

export const useScriptLoaderWithRetry = (
	src: string,
	handleSdkError?: () => void // Accept `handleSdkError` as a parameter
): UseScriptLoaderResult => {
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [isScriptLoaded, setScriptLoaded] = useState(false);

	const reloadFinixScript = useCallback(() => {
		const existingScript = document.querySelector(`script[src="${src}"]`);

		if (existingScript) {
			if (existingScript?.parentNode) {
				existingScript.parentNode.removeChild(existingScript);
			}
		}

		const script = document.createElement('script');
		script.src = src;
		script.async = true;

		const handleLoad = () => {
			setIsLoading(false);
			setScriptLoaded(true);
			setError(null);
		};

		const handleError = () => {
			// eslint-disable-next-line no-console
			console.error(`Failed to load script: ${src}`);
			if (handleSdkError) {
				handleSdkError();
			}
			setIsLoading(false);
			setError(`Failed to load script: ${src}`);
			if (script?.parentNode) {
				script.parentNode.removeChild(script);
			}
		};

		script.onload = handleLoad;
		script.onerror = handleError;

		setIsLoading(true);
		document.body.appendChild(script);
	}, [src, handleSdkError]);

	return { isLoading, isScriptLoaded, error, reloadFinixScript };
};
